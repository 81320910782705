import * as React from "react";
import styled from "@emotion/styled";

type Props = {
    dateText: string;   
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--Blue, #7f96ff);
  font-weight: 500;
  text-align: center;
  justify-content: center;
  border: #7f96ff solid 1px ;
  border-radius: 30px;
  
    width: 65px;
  min-width: 65px;
  max-width: 65px;
`;

const DateText = styled.div`
  font-family: Open Sans, sans-serif;
  border-radius: 30px;
  background-color: var(--White, #fff);
  justify-content: center;
  padding:5px;
  
    font-size: 12px;    
  height: 18px;
`;

const DateIcon = ({dateText}: Props) => {
    return (
        <Wrapper key={self.crypto.randomUUID()}>
            <DateText key={self.crypto.randomUUID()}>{dateText}</DateText>
        </Wrapper>
    );
}


export default DateIcon;

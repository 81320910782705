import React, { useState } from 'react';
import styled from '@emotion/styled';
import previousIcon from '../Images/PreviousIcon.svg';
import greyPreviousIcon from '../Images/greyPreviousIcon.svg';
import { IconButton } from '@mui/material';

type Props = {
    startIndex: number;
    onClick: () => void;
}


const Icon = styled.img`
width: 28px;
overflow: hidden;
height: 28px;


`;

const PreviousIcon = ({ startIndex, onClick }: Props) => {

    return (
        <IconButton onClick={onClick} disabled= {startIndex ==0 ?true:false}>

            <Icon src={startIndex == 0 ? greyPreviousIcon :previousIcon} alt="Next"  />

        </IconButton>
    );
};
export default PreviousIcon;

import React from 'react';
import { ImSpinner2 } from 'react-icons/im';
import '../styles.css';
import { Box, LinearProgress } from '@mui/material';

const Loader = () => {
    return (
        <><Box sx={{
            width: '100%', color: 'rgba(127, 150, 255, 1)', height: '4px'
        }}><LinearProgress color="inherit" />
        </Box></>
       
    );
};
export default Loader;
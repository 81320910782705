import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa';
import { TimelineItem } from '../../globalTypes/objects';
import EditStatus from './EditStatus';
import { useToken } from "../../useToken";
import SchoolUploadIcon from './SchoolUploadIcon';
import SchoolProcessing from '../Processing/SchoolProcessing';
import SelectedSchoolContext from '../SelectedSchoolContext';
import SchoolDownloadIcon from './SchoolDownloadIcon';
import { NoteButton } from '../Processing/ProcessingStyles';
import { Box, Card, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, tableCellClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FilterButton, HeaderCell, BodyCell, OverdueStatusBodyCell, DocumentBodyCell, FilterText, FilterButtonText, DateReceivedCell, SortButton } from './ProjectsStyles';
import ProjectItem from './ProjectCell';
import StatusCell from './StatusCell';
import { MdEdit, MdFlag, MdPartyMode, MdWarning } from 'react-icons/md';
import filterIcon from '../../Images/filterIcon.svg';
import partyIcon from '../../Images/partyIcon.svg';
import warningIcon from '../../Images/warningIcon.svg';
import paperClipIcon from '../../Images/paperclipIcon.svg';
import moment from 'moment';
import FilterCounter from './FilterCounter';
import { useAppSelector } from '../../store';
import { API_BASE_URL } from '../../urlConfig';
import DownloadDocumentsModal from './DownloadDocumentsModal';
import filterButtonIcon from '../../Images/sortIcon.svg';
import FilterModal from './FilterModal';
import UserAvatar from '../UserAvatar';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: 0,
    minWidth: 175,
    verticalAlign: 'center',


}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));



const Projects = () => {
    const [timeline, setTimeline] = useState<TimelineItem[]>([]);
    const [filteredData, setFilteredData] = useState<TimelineItem[]>([]);
    const [sortedData, setSortedData] = useState<TimelineItem[]>();
    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
    const [selectedProject, setSelectedProject] = useState<string | null>(null);
    const [upcomingCounter, setUpcomingCounter] = useState<number>(0);
    const [historyCounter, setHistoryCounter] = useState<number>(0);
    const [viewAllCounter, setViewAllCounter] = useState<number>(0);
    const [roles, setRoles] = useState<string[] | string>();
    const [selectedStatus, setSelectedStatus] = useState<string>('upcoming');
    const [openDocumentModal, setOpenDocumentModal] = useState(false);
    moment.locale('en-us');
    const [loading, setLoading] = useState(true);
    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;
    const selectedSchoolName = schoolState.name;
    const [filterTypes, setFilterTypes] = useState<string[]>([]);
    const [openFilterModal, setOpenFilterModal] = useState(false);

    const getToken = useToken();
    const getTimeline = async (isInitial: boolean) => {
        try {
            const localToken = await getToken();
            const { data } = await axios.get(`${API_BASE_URL}/api/Timeline/getTimelineYearlyOverview/${selectedSchoolId}`, {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setTimeline(data);
            const now = new Date().setHours(0, 0, 0, 0);// new Date(item.deadlineDate).setHours(0, 0, 0, 0) >= now || new Date(item.deadlineDate).setHours(0, 0, 0, 0) < now &&
            const initialLoadData = data?.filter((item: { deadlineDate: string; status: string }) => (item.status == "Incomplete" || item.status == "To Do" || item.status == "Overdue"))
            const historyData = data?.filter((item: { deadlineDate: string; status: string }) => (item.status == "Uploaded" || item.status == "Complete"))
            if (isInitial) {
                setFilteredData(initialLoadData);
            }
            else { updateTimeline(selectedStatus, data); }

            const countUpcoming = initialLoadData.length;
            setUpcomingCounter(countUpcoming);
            const countHistory = historyData.length;
            setHistoryCounter(countHistory);
            setViewAllCounter(countUpcoming + countHistory);

        } catch (error: any) {
            //todo
            console.log(error.message);
        }
    };


    const updateTimeline = (selectedStatusFilter: string, timelineDate: TimelineItem[]) => {
        const now = new Date().setHours(0, 0, 0, 0);
        const filteredTimeline = selectedStatusFilter.toLowerCase() === "upcoming" ? timelineDate?.filter((item: { status: string }) => (item.status == "Incomplete" || item.status == "To Do" || item.status == "Overdue"))
            : timelineDate?.filter(item => (item.status == "Uploaded" || item.status == "Complete"));
        setFilteredData(filteredTimeline);


    };

    const handleButtonClick = (selectedStatusFilter: string) => {
        setSelectedStatus(selectedStatusFilter);
        updateTimeline(selectedStatusFilter, timeline);

    };

    const handleEditClick = (itemId: number, project: string) => {
        setSelectedItemId(itemId);
        setSelectedProject(project);
    };

    const handleModalClose = () => {
        getTimeline(false);
    };

    const handleEditModalClose = () => {
        setSelectedItemId(null);
        setSelectedProject(null);
        getTimeline(false);
    };
    const getDateReceivedIcon = async () => {
        const now = new Date().setHours(0, 0, 0, 0);

    }

    const formatDate = (dateString: string) => {
        const date = moment(dateString);

        let formatedDate = date.format("MMMM DD, YYYY");
        formatedDate = formatedDate == 'Invalid date' ? '' : formatedDate;

        return formatedDate;

    };
    const formatDateReceived = (dateString: string) => {
        const date = moment(dateString);

        let formatedDate = date.format("MMM DD, YYYY");
        formatedDate = formatedDate == 'Invalid date' ? '' : formatedDate;

        return formatedDate;

    };
    const setUserRoles = async () => {
        const localToken = await getToken();
        setRoles(localToken?.roles);

    };

    const handleFilterModalClose = async () => {
        setOpenFilterModal(false);
        filterTypes.forEach((type) => {
            switch (type) {
                case type = "Project":
                    setFilteredData(filteredData.sort((a, b) => a.taskDescription.localeCompare(b.taskDescription)));

                    break;
                case type = "HESDeadline":
                    setFilteredData(filteredData.sort((a, b) => a.hesDueDate.localeCompare(b.hesDueDate)));
                    break;
                case type = "Deadline":
                    setFilteredData(filteredData.sort((a, b) => a.deadlineDate.localeCompare(b.deadlineDate)));

                    break;
                case type = "DateReceived":
                    setFilteredData(filteredData.sort((a, b) => (a.dateReceived ?? '').localeCompare((b.dateReceived ?? ''))));
                    break;
                case type = "Status":
                    setFilteredData(filteredData.sort((a, b) => a.status.localeCompare(b.status)));
                    break;
                case type = "Clear":
                    setFilteredData(filteredData);
                    break;
            }
        });
        setLoading(false);
    };


    useEffect(() => {
        if (timeline.length < 1) {

            getTimeline(true).catch(error => {
                console.error('Error getting timeline:', error);
            });
            setUserRoles();
        }
    }, [filteredData]);



    useEffect(() => {
        getTimeline(true).catch(error => {
            console.error('Error getting timeline:', error);
        });
        handleButtonClick('upcoming');
    }, [selectedSchoolId]);
    return (
        <div>
            <div className="pageHeader">Projects and Reports</div>
            {openDocumentModal && <DownloadDocumentsModal onClose={() => setOpenDocumentModal(false)} projectId={selectedItemId ?? 0} />}
            {openFilterModal && <FilterModal onClose={() => { setLoading(true); handleFilterModalClose() }} filterTypes={filterTypes} setFilterFunction={setFilterTypes} />}
            <TableContainer sx={{ width: '85%', maxWidth: '1400px', overflow: 'auto', padding: '5px', marginTop: '38px', borderRadius: '20px', boxShadow: '0px 0px 20px #8397f821' }} component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={6}>
                                <div style={{ display: 'flex', gap: '8px' }}>
                                    <SortButton><img onClick={() => { setOpenFilterModal(true) }} src={filterButtonIcon} alt="Filter Icon" /></SortButton>
                                    <FilterButton style={selectedStatus == 'upcoming' ? { color: '#fff', backgroundColor: '#253872' } : { backgroundColor: '#fff', color: '#253872' }}
                                        onClick={() => handleButtonClick('upcoming')}>
                                        <FilterButtonText >To Do</FilterButtonText>
                                        <FilterCounter selectedStatus={selectedStatus} count={upcomingCounter} filterName='upcoming' /></FilterButton>
                                    <FilterButton style={selectedStatus == 'history' ? { color: '#fff', backgroundColor: '#253872' } : { backgroundColor: '#fff', color: '#253872' }}
                                        onClick={() => handleButtonClick('history')}>
                                        <FilterButtonText>Done</FilterButtonText>
                                        <FilterCounter selectedStatus={selectedStatus} count={historyCounter} filterName='history' /></FilterButton>
                                    <FilterButton style={selectedStatus == 'viewall' ? { color: '#fff', backgroundColor: '#253872' } : { backgroundColor: '#fff', color: '#253872' }}
                                        onClick={() => (setSelectedStatus('viewall'), setFilteredData(timeline))}>
                                        <FilterButtonText>All</FilterButtonText>
                                        <FilterCounter selectedStatus={selectedStatus} count={viewAllCounter} filterName='viewall' /></FilterButton>    </div>
                            </TableCell>

                        </TableRow>

                        <TableRow>
                            <StyledTableCell><HeaderCell style={{ width: '180px' }}>Project/Report</HeaderCell></StyledTableCell>
                            <StyledTableCell><HeaderCell>HES Deadline</HeaderCell></StyledTableCell>
                            <StyledTableCell><HeaderCell>Deadline</HeaderCell></StyledTableCell>
                            <StyledTableCell><HeaderCell>Date Received</HeaderCell></StyledTableCell>
                            <StyledTableCell><HeaderCell>Download/Upload</HeaderCell></StyledTableCell>
                            <StyledTableCell><HeaderCell>Status</HeaderCell></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData &&
                            filteredData.map(x => (
                                <TableRow
                                    key={x.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StyledTableCell component="th" scope="row">
                                        <ProjectItem projectDescription={x.taskDescription} status={x.status} />
                                    </StyledTableCell>
                                    <StyledTableCell ><BodyCell >{formatDate(x.hesDueDate)}
                                    </BodyCell></StyledTableCell>
                                    <StyledTableCell ><BodyCell>{formatDate(x.deadlineDate)}</BodyCell></StyledTableCell>
                                    <StyledTableCell >
                                        <DateReceivedCell style={{ display: 'flex' }}>

                                            <Box sx={{ width: '30px', paddingTop: '12px', paddingLeft: '5px' }}>
                                                {x.dateReceived && new Date(x.dateReceived).setHours(0, 0, 0, 0) <= new Date(x.hesDueDate).setHours(0, 0, 0, 0) ?
                                                    <span style={{ fontSize: '18px' }}>🎉</span> :
                                                    new Date().setHours(0, 0, 0, 0) >= new Date(x.hesDueDate).setHours(0, 0, 0, 0) && new Date().setHours(0, 0, 0, 0) < new Date(x.deadlineDate).setHours(0, 0, 0, 0) ?
                                                        <MdWarning fontSize={'20px'} color={'#ffcb00'}></MdWarning> :
                                                        x.status == 'Overdue' ? <MdFlag fontSize={'18px'} color={'rgb(251, 74, 74)'}></MdFlag> : <span></span>

                                                }
                                            </Box>
                                            <Box sx={{ width: '100px', paddingTop: '15px', paddingLeft: '5px' }} >
                                                {formatDateReceived(x.dateReceived)}
                                            </Box>
                                            {/*  <Box sx={{ width: '30px', paddingTop: '10px' }} borderLeft={x.hasSubmittedFiles ? '1px rgba(37, 56, 114, 0.14) solid ' : '0px'}>  {x.hasSubmittedFiles && <img onClick={() => { setOpenDocumentModal(true), setSelectedItemId(x.id) }} style={{ width: '30px', cursor: 'pointer' }} src={paperClipIcon}></img>}*/}
                                            {/*</Box>*/}



                                        </DateReceivedCell></StyledTableCell>
                                    <StyledTableCell style={{ display: 'flex' }}><DocumentBodyCell>
                                        <SchoolDownloadIcon documentIds={x.documentIds} filePath={x.filePath} fileName={x.fileName} projectId={x.id} schoolId={selectedSchoolId} projectDescription={x.taskDescription} />
                                        <SchoolUploadIcon projectDescription={x.taskDescription} projectId={x.id} schoolId={selectedSchoolId} onClose={() => handleModalClose()} /></DocumentBodyCell></StyledTableCell>
                                    {/*<StyledTableCell onClick={() => (roles?.includes('SuperAdmin') || roles?.includes('Admin') || roles?.includes('Processor')) ? handleEditClick(x.id, x.taskDescription) : {}} >*/}{/* <MdEdit color='#253872' fontSize="large" onClick={() => handleEditClick(x.id)} />*/}
                                    <StyledTableCell>
                                        <StatusCell status={x.status} projectId={x.id} projectNotes={x.timelineNotes[0] != null ? x.timelineNotes : []} projectDescription={x.taskDescription} onStatusClick={() => (roles?.includes('SuperAdmin') || roles?.includes('Admin') || roles?.includes('Processor')) ? handleEditClick(x.id, x.taskDescription) : {}} isCard={false} />
                                    </StyledTableCell>
                                    <StyledTableCell><UserAvatar key={x.createdBy} userEmail={x.createdBy} hover={true} />
                                    </StyledTableCell>

                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>


            {selectedItemId && selectedProject &&
                <EditStatus
                    id={selectedItemId} projectDescription={selectedProject}
                    onClose={() => handleEditModalClose()} />}

        </div>

    );
};

export default Projects;


import React, { useEffect, useState } from 'react';
import { Paper, TableContainer, Table, CircularProgress, TableBody, styled, TableRow, TableCell, TableFooter, Button } from '@mui/material';
import { SearchBar } from '../SearchBar';
import { useToken } from '../../useToken';
import axios from 'axios';
import { API_BASE_URL } from '../../urlConfig';
import moment from 'moment';
import { FeedbackItem } from '../../globalTypes/objects';
import { HigherEdInfo, HigherEdInfoName, HigherEdInfoNumAddress, Footer } from './ContactsStyles';
import phoneIcon from '../../Images/phoneIcon.svg';
import envolopeIcon from '../../Images/envolopeIcon.svg';
import addressIcon from '../../Images/addressIcon.svg';
import phoneCircleIcon from '../../Images/phoneCircleIcon.svg';
import { useAppSelector } from '../../store';
import { User } from '@auth0/auth0-react';
import { Department, UserModel } from '../../types';
import EditContactsModal from './EditContactsModal';
import UserAvatar from '../UserAvatar';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontSize: '16px',
    width: '200px',
    color: '#253872',
    fontWeight: '600',
    paddingLeft: '25px',
    height: '26px',
    paddingTop: '12px',
    border: 0,
}));
const StyledTableCellImage = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontSize: '16px',
    border: 0,
    color: '#253872',
    fontWeight: '600',
    height: '26px',
    maxWidth: '20px',
    paddingTop: '20px',
    paddingBottom: '12px',
    paddingRight: '0px',
    paddingLeft: '100px',
    width: '20px',
}));
const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontSize: '14px',
    height: '26px',
    color: '#253872',
    paddingTop: '18px',
    paddingBottom: '12px',
    paddingLeft: '0px',
    fontWeight: '400',
    width: '200px',
    border: 0
}));
const StyledTableCell3 = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontSize: '14px',
    height: '26px',
    color: '#253872',
    padding: '0',
    fontWeight: '400'
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: '1px solid rgba(37, 56, 114, 0.05)',
    '&:nth-of-type(even)': {
        //  backgroundColor: 'rgba(131, 151, 248, 0.07)',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        borderBottom: '0px',
    },
}));

const formatDate = (dateString: string) => {
    const date = moment(dateString);
    const formatedDate = date.format("MMMM DD");
    return formatedDate;

};

const Contacts = () => {
    const getToken = useToken();
    const [searchValue, setSearchValue] = useState<string>("");
    const [schoolContactList, setSchoolContactList] = useState<UserModel[]>([]);
    const [generalContactList, setGeneralContactList] = useState<UserModel[]>([]);
    const [contactList, setContactList] = useState<UserModel[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [roles, setRoles] = useState<string[] | string>();
    const [openEditContactsModal, setOpenEditContactsModal] = useState(false);
    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;
    const selectedSchoolName = schoolState.name;

    moment.locale('en-us');
    const setUserRoles = async () => {

        const localToken = await getToken();
        setRoles(localToken?.roles);
    };
    const getSchoolContacts = async () => {
        try {
            const localToken = await getToken();
            const { data } = await axios.get(`${API_BASE_URL}/api/department/getSchoolContacts/${selectedSchoolId}`, {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });

            //   data.sort((a: { department: Department; }, b: { department: Department; }) => (a?.department?.sortorderid ?? 0) - (b?.department?.sortorderid ?? 0));
            setSchoolContactList(data);
            //const { generalData } = await axios.get(`${API_BASE_URL}/api/department/getGeneralContacts`, {
            //    headers:
            //    {
            //        'Authorization': `Bearer ${localToken?.accessToken}`
            //    }
            //});
            setLoading(false);
            setError('');
        } catch (error: any) {
            setError(error.message);
            setLoading(false);
        }
    };


    const getGeneralContacts = async () => {
        try {
            const localToken = await getToken();

            const { data } = await axios.get(`${API_BASE_URL}/api/department/getGeneralContacts`, {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setGeneralContactList(data);
            setLoading(false);
            setError('');
        } catch (error: any) {
            setError(error.message);

            setLoading(false);
        }
    };
    const handleEditContactsModalClose = async () => {
        setOpenEditContactsModal(false);
        getSchoolContacts();
        getGeneralContacts();
    };


    const combineContacts = async () => {
        setContactList(schoolContactList);
        const allContacts = [...schoolContactList];

        // Concatenate the new items
        allContacts.push(...generalContactList);
        //  allContacts.sort((a: Department, b: Department) => (a?.sortOrderId ?? 0) - (b?.sortOrderId ?? 0));
        setContactList(allContacts.sort((a, b) => (a?.department?.sortOrderId ?? 0) - (b?.department?.sortOrderId ?? 0)));
        // Update the state
        setContactList(allContacts);

    };


    useEffect(() => {
        getSchoolContacts();
        getGeneralContacts();

        setUserRoles();

    }, [selectedSchoolId, loading]);

    useEffect(() => {
        if (generalContactList.length > 0 || schoolContactList.length > 0) {
            combineContacts();
        }
    }, [ schoolContactList, generalContactList]);

    return (

        <div>  <div className="pageHeader">HES Contacts</div>

            <TableContainer sx={{
                width: '85%', maxWidth: '1400px', maxHeight: '500px', minHeight: '100px', overflow: 'auto', padding: '0px', marginTop: '38px', borderRadius: '10px', background: 'white', boxShadow: '0px 0px 20px rgba(131, 151, 248, 0.13)'
            }} component={Paper}>
                {/*   {loading && <CircularProgress sx={{ color: '#B1B1B1', marginLeft: '45%' }} />}*/}
                <Table>


                    <>        <TableBody sx={{ padding: 0 }}>
                        {contactList &&
                            contactList.map(contact => (
                                
                                <StyledTableRow style={contact.department && contact.department.departmentName && contact.department.departmentName.indexOf('Academic Processor')
                                    > -1 ?
                                    { backgroundColor: 'rgba(187, 150, 131, 0.13) ' } : contact.department && contact.department.departmentName
                                        && (contact.department.departmentName.indexOf('Financial Aid Processor') > -1 ||
                                            contact.department.departmentName.indexOf('TAP Processor') > -1) ? { backgroundColor: 'rgba(131, 151, 248, 0.05) ' }
                                        : contact.department && contact.department.departmentName && contact.department.departmentName.indexOf('Tuition Processor') > -1
                                            ? { backgroundColor: 'rgba(37, 56, 114, 0.1) ' } : {}} sx={contact.department?.departmentName == "Founder" ? { borderTop: '2px solid rgba(37, 56, 114, 0.5)' } : {}} >
                                    <StyledTableCell style={{ width: '8px' }}>
                                        <UserAvatar key={contact.email} userEmail={contact.email ?? ''} hover={false} /></StyledTableCell>

                                    <StyledTableCell>
                                        {contact.title} {contact.title == 'Rabbi' || contact.title == 'Mr' ? contact.firstName : contact.firstName?.substring(0, 1)} {contact?.lastName}
                                    </StyledTableCell>


                                    <StyledTableCellImage ><a href={'mailto:' + contact.email}><img width={'14px'} height={'14px'} src={envolopeIcon} style={{ paddingRight: '8px' }}></img></a></StyledTableCellImage>

                                    <StyledTableCell2> {contact.email} </StyledTableCell2>

                                    <StyledTableCellImage>  <a href={'tel:732.660.9090,' + contact.phoneExtension}  ><img width={'17px'} height={'17px'} style={{ paddingRight: '8px' }} src={phoneIcon}></img></a> </StyledTableCellImage>
                                    <StyledTableCell2 >
                                        {contact.phoneExtension}   </StyledTableCell2>
                                    <StyledTableCell2> {contact.department?.hideDepartment != true ? contact.department?.departmentName : ''}    </StyledTableCell2>
                                </StyledTableRow>
                            ))}


                    </TableBody>

                    </>
                </Table>

            </TableContainer>
            <Footer>
                <HigherEdInfo style={{ marginRight: '130px' }}>
                    <HigherEdInfoNumAddress>
                        <a href={'tel:732.660.9090'} > <img style={{ marginLeft: '30px', marginBottom: '20px' }} src={phoneCircleIcon}></img></a>
                        <div>732.660.9090</div>
                    </HigherEdInfoNumAddress>
                </HigherEdInfo>

                <HigherEdInfo >
                    <HigherEdInfoNumAddress>
                        <a href={'https://maps.app.goo.gl/69pY7UFHaTTpJoU76'}> <img style={{ marginLeft: '45px', marginBottom: '20px' }} src={addressIcon}></img></a>
                        <div>450 W Kennedy Blvd</div>
                        <div>Lakewood NJ 08701</div>
                    </HigherEdInfoNumAddress>

                </HigherEdInfo></Footer>
            {error && <div>{error}</div>}
        </div>

    );
};

export default Contacts;


import React, { useContext, useEffect, useState } from 'react';
import { StudentFile, StudentFolder } from '../../globalTypes/objects';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useAuth0 } from "@auth0/auth0-react";
import { Elipse, HeaderElipse, InactiveTag, StudentId, StudentName } from './FoldersStyles';
import '../../styles.css';
import { Box, Card, Checkbox, CircularProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import filterIcon from '../../Images/filterIcon.svg';
import Button from '@mui/material/Button';
import "@fontsource/open-sans";
import { useAppSelector } from '../../store';
import { useToken } from '../../useToken';
import saveAs from 'file-saver';
import { API_BASE_URL } from '../../urlConfig';
import downloadIcon from '../../Images/downloadPurpleIcon.svg';
import filterButtonIcon from '../../Images/filterButtonIcon.svg';
import styledE from "@emotion/styled";
import { FilterText } from '../Projects/ProjectsStyles';
import { MdPrint } from 'react-icons/md';
import { Item } from 'semantic-ui-react';
import JSZip, { folder } from 'jszip';
import printJS from 'print-js';
import FilterModal from './FilterModal';
import NextIcon from '../NextIcon';
import PreviousIcon from '../PreviousIcon';
import { FaL } from 'react-icons/fa6';
import moment from 'moment';
import DateIcon from './DateIcon';
/*import { cocatBlobs, postToServer } from 'ConcatenateBlobs';*/

type Props = {
    studentFolders: StudentFolder[];

}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontSize: '16px',
    width: '1300px',
    display: 'flex', marginLeft: '24px'
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const Folders = ({ studentFolders }: Props) => {
    const getToken = useToken();
    //  const [folders, setFolders] = useState<StudentFolder[]>([]);
    const [filterTypes, setFilterTypes] = useState<string[]>([]);
    const [error, setError] = useState('');
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [isInitial, setIsInitial] = useState(true);
    const [showInactive, setShowInactive] = useState(false);
    const [showActive, setShowActive] = useState(true);
    const [allChecked, setAllChecked] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [seed, setSeed] = useState(1);
    const [yearIndex, setYearIndex] = useState<number>(0);

    const [yearsList, setYearsList] = useState<string[]>([]);
    const [years, setYears] = useState<string>('');
    // setFolders(studentFolders);

    moment.locale('en-us');
    const formatDate = (dateString: string) => {
    const date = moment.utc(dateString);
    const formatedDate = date.format("MMM DD");
    return formatedDate;

};

    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, studentId: number) => {
        const updatedFolders = [...studentFolders];
        updatedFolders.forEach((folder) => {
            if (folder.externalStudentId === studentId) {
                folder.isChecked = event.target.checked;
                setSeed(Math.random());
            }
        });
        studentFolders = (updatedFolders);
        const isEveryBoxChecked = studentFolders.filter(x => x.years == years).every((folder) => folder.isChecked);
        setAllChecked(isEveryBoxChecked);

    };
    const handleAllCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllChecked(event.target.checked);
        const allFolders = [...studentFolders];
        allFolders.forEach((folder) => {

            folder.isChecked = event.target.checked;

        });
        studentFolders = (allFolders);
    };

    const handleFilterModalClose = async () => {
        setOpenFilterModal(false);
        filterTypes.forEach((type) => {
            switch (type) {
                case type = "Alpha":
                    studentFolders = (studentFolders.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName)));
                    setSeed(Math.random());
                    break;
                case type = "StudentId":
                    studentFolders = (studentFolders.sort((a, b) => a.externalStudentId - b.externalStudentId));
                    setSeed(Math.random());
                    break;
                case type = "DateAsc":
                    studentFolders = (studentFolders.sort((a, b) => new Date(a.isirDate).getTime() - new Date(b.isirDate).getTime()));
                    setSeed(Math.random());
                    break;
                case type = "DateDesc":
                    studentFolders = (studentFolders.sort((a, b) => new Date(b.isirDate).getTime() - new Date(a.isirDate).getTime()));
                    setSeed(Math.random());
                    break;
                //case type = "InactiveStudents":
                //    setShowInactive(true);
                //    setShowActive(false);
                //    setSeed(Math.random());
                //    break;
                case type = "Reset":
                    studentFolders = (studentFolders.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName)));
                    setShowInactive(false);
                    setShowActive(true);
                    setSeed(Math.random());
                    break;
            }
        });
        setLoading(false);
    };

    useEffect(() => {


        if (studentFolders.length > 0 && isInitial) {
            setLoading(false);
            const yearListInit = [...new Set(studentFolders.map(item => item["years"]))].reverse();// ((a, b) => a.localeCompare(b));
            setYearsList(yearListInit);
            setYears(yearListInit[0]);

            setIsInitial(false);
        } else {
            setLoading(false);
        }

    }, [studentFolders, filterTypes, seed, showActive, showInactive, loading]);



    const handleSelectedFoldersDownload = async () => {
        if (studentFolders.filter(x => x.isChecked).length < 1) {
            alert('Please select a student to download.');
        }
        const unresolvedPromises = studentFolders.map(async (folder) => {
            if (folder.isChecked && folder.years == years) {
                downloadStudentFolder(folder.studentFiles, folder.firstName + "_" + folder.lastName + "_" + folder.externalStudentId + "_" + years, folder.externalStudentId, folder.schoolId, folder.folderType);
            }
        });
        const results = await Promise.all(unresolvedPromises);
    }

    const downloadStudentFolder = async (studentFiles: StudentFile[], studentName: string, studentId: number, schoolId: number, folderCategory: string) => {


      //  const zip = new JSZip();
        for (let i = 0; i < studentFiles.length; i++) {
            try {
                const localToken = await getToken();
                const filePath = studentFiles[i].filePath;
                const fileName = studentFiles[i].fileName;
                const newFilePath = filePath.replaceAll('/', '*');
                logDownloadStudentFolder(fileName, studentId, schoolId, folderCategory);
                const response = await axios.get(`${API_BASE_URL}/api/Folders/downloadFile/${fileName}/${newFilePath}`

                    , {
                        responseType: 'arraybuffer',
                        headers: {
                            'Authorization': `Bearer ${localToken?.accessToken}`
                        }
                    });
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
               // zip.file(studentFiles[i].fileName, blob);
                saveAs(blob, studentFiles[i].fileName);
               

            } catch (e) {
                console.error('Download failed', e);
            }
        }
        //zip.generateAsync({ type: "blob" })
        //    .then(function (content) {
        //        saveAs(content, studentName + ".zip");
        //    });
    };

    const logDownloadStudentFolder = async (fileName: string, studentId: number, schoolId: number, folderCategory: string) => {
        const localToken = await getToken();
        axios.post(`${API_BASE_URL}/api/Logger/addDownloadStudentFileLog/${fileName}/${schoolId}/${studentId}/${folderCategory}`, {}
            , {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            })
            //.then(() => {
            //    console.log( "hit here");
            //  //  setResponse("Successfully updated user schools.");

            //})
            .catch(error => {
                console.log(error?.response?.data);

            })
    };

    const handleFilesPrint = async (studentFiles: StudentFile[]) => {
        const blobs: Blob[] = [];
        for (let i = 0; i < studentFiles.length; i++) {
            try {
                const localToken = await getToken();
                const filePath = studentFiles[i].filePath;
                const fileName = studentFiles[i].fileName;
                const newFilePath = filePath.replaceAll('/', '*');

                const response = await axios.get(`${API_BASE_URL}/api/Folders/downloadFile/${fileName}/${newFilePath}`

                    , {
                        responseType: 'arraybuffer',
                        headers: {
                            'Authorization': `Bearer ${localToken?.accessToken}`
                        }
                    });
                const arr1 = new Uint8Array(response.data);
                const buf1 = arr1.buffer;
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                blobs.push(blob);
                //const uri = URL.createObjectURL(blob);(studentFiles[i].fileName,
                //printJS({ printable: uri });

            } catch (e) {
                console.error('Download failed', e);
            }
        }
        //zip.generateAsync({ type: "blob" })
        //    .then(function (content) {
        // saveAs(content, studentName + ".zip");
        // let uri = '';

        // uri = URL.createObjectURL(blobs);
        const combineBlob = new Blob(blobs, { type: 'application/octet-stream' });
        const uri2 = window.URL.createObjectURL(combineBlob);
        printJS({ printable: uri2 });
        //const uri = URL.createObjectURL(blobs);
        //const iframe = document.createElement('iframe'); //load content in an iframe to print later
        //document.body.appendChild(iframe);

        //iframe.style.display = 'none';
        //iframe.src = uri;
        //iframe.onload = function () {
        //    setTimeout(function () {
        //        iframe.focus();
        //        iframe?.contentWindow?.print();
        //    }, 1);
        //};

        // });        






        //try {
        //    if (blob != null) {
        //        const uri = URL.createObjectURL(blob);
        //        printJS({ printable: uri });
        //    }

        //} catch (e) {
        //    console.error('Download failed', e);
        //    alert('Download failed   ' + e.message);
        //}

    };
    const handleArrowClick = (clickType: string) => {
        const addNumber = clickType == "Next" ? -1 : +1;
        const nextYearIndex = yearIndex != null ? yearIndex + addNumber : 0;

        const maxEnd = yearsList.length - 1;
        const newIndex = nextYearIndex < 0 ? 0 : nextYearIndex > maxEnd ? maxEnd : nextYearIndex;
        setYearIndex(newIndex);

        setYears(yearsList[newIndex]);
        setSeed(Math.random());
    };
    const showFolder = (folder: StudentFolder) => {

        if (folder.years === years) {
            return true;
        } else {
            return false;
        }

    };



    const tabStyle = {
        padding: '10px 20px',
        cursor: 'pointer',
        listStyleType: 'none',
        border: ' 0px solid #fff'
    };




    return (
        <>
            {openFilterModal && <FilterModal folderCategory={"ISIRS"} onClose={() => { setLoading(true); handleFilterModalClose() }} filterTypes={filterTypes} setFilterFunction={setFilterTypes} />}
            <>

                <Box sx={{
                    width: '1400px', borderBottomWidth: '1px', borderBottomStyle:'solid', borderBottomColor:'#F4F5F7' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '85%' }}></div>
                        <div style={{ display: 'flex', width: '15%' }}>
                            <PreviousIcon startIndex={ 1} onClick={() => handleArrowClick("Previous")} />
                            <div style={{ paddingTop: '12px', paddingLeft: '2px', paddingRight: '2px' }}> {years}</div>
                            <NextIcon showNext={false} onClick={() => handleArrowClick("Next")} />
                        </div>
                    </div>  <div style={{ display: 'flex' }} >   <div style={{ width: '80%', marginLeft: '24px', display: 'flex' }}>
                        <div><img onClick={() => { setOpenFilterModal(true) }} src={filterButtonIcon} alt="Filter Icon" /></div>
                        <div style={{ display: 'flex', marginLeft: '24px' }}>  <Checkbox checked={allChecked} onChange={handleAllCheckBoxChange} sx={{
                            marginTop: '0px',
                            color: 'rgba(131, 151, 248, 0.50)', '&.Mui-checked': { color: 'rgba(131, 151, 248, 0.50)', },
                            height: '42px', width: '42px'
                        }} />
                            <div style={{ marginTop: '9px' }}>Select All</div></div>
                    </div>
                        <div style={{ width: '15%', display: 'flex', paddingBottom: '5px' }}>
                            <img src={downloadIcon} onClick={() => (handleSelectedFoldersDownload())} style={{ cursor: 'pointer', width: '40px', height: '40px', marginTop: '5px' }}></img>
                            {/* <HeaderElipse><MdPrint color={'rgb(127, 150, 255)'} fontSize={'20'} style={{  marginTop: '11px', marginLeft: '10px' }} /></HeaderElipse>*/}


                        </div></div>

                </Box>

                <TableBody key={seed} sx={{ width: '500px' }} >
                    <tr><td>    {loading && <CircularProgress sx={{ color: '#B1B1B1', marginLeft: '500px' }} />}
                        {!loading && studentFolders && studentFolders.length == 0 && <div style={{ paddingLeft: '25px', paddingTop: '25px' }}>No Files Found</div>}
                    </td></tr>
                    {!loading && studentFolders && studentFolders.map((folder, index) => (

                        <StyledTableRow key={index} style={showFolder(folder)
                            // && (showActive && folder.isCurrent && folder.years == years ? { display: 'block' } : !showActive && folder.isCurrent ? { display: 'none' } : showInactive && !folder.isCurrent ? { display: 'block' } : !showInactive && !folder.isCurrent
                            ? { display: 'block' } : { display: 'none' }}>
                            <StyledTableCell>

                                <Checkbox id={"ckStudent" + folder.externalStudentId} checked={folder.isChecked ?? false}
                                    name={'studentCheckbox'} onChange={(e) => { handleCheckBoxChange(e, folder.externalStudentId) }}
                                    sx={{
                                        color: 'rgba(131, 151, 248, 0.50)', '&.Mui-checked': { color: 'rgba(131, 151, 248, 0.50)', }, height: '38px', width: '38px'
                                    }} />
                                <div style={{ width: '75%', display: 'flex' }}>
                                    <StudentName> {folder.firstName + " " + folder.lastName}</StudentName>
                                    <StudentId>#{folder.transNumber}</StudentId>
                                      
                             
                                </div>
                                <div style={{
                                    width: '10%'
                                }} >
                                    <DateIcon key={self.crypto.randomUUID()} dateText={formatDate(folder.isirDate)} />
                                </div>
                                <div style={{ width: '15%', display: 'flex' }}>
                                    
                                    <img src={downloadIcon} onClick={() => (downloadStudentFolder(folder.studentFiles, folder.firstName + "_" + folder.lastName + "_" + folder.transNumber + "_" + years, folder.externalStudentId, folder.schoolId, folder.folderType))} style={{ cursor: 'pointer', width: '30px', height: '30px', marginTop: '5px' }}></img>
                                    {/* <Elipse><MdPrint onClick={() => { handleFilesPrint(folder.studentFiles) }} color={'rgb(127, 150, 255)'} fontSize={'20'} style={{ marginTop: '6px', marginLeft: '5px' }} />  </Elipse>*/}
                                </div>   </StyledTableCell>
                        </StyledTableRow>

                    ))}
                </TableBody>
            </>

        </>
    );
};

export default Folders;


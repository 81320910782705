import React, { useEffect, useState } from 'react';
import { ModalBody, ModalContent, ModalHeader, ModalOverlay } from './ProcessingStyles';
import UploadFile from '../UploadFile';
import { Need, StudentRequirement } from '../../globalTypes/objects';
import StudentData from './StudentData';
import NeedItem from './NeedItem';
import Button from '@mui/material/Button';
import { CloseButton } from '../CloseButton';
import UploadFileProcessing from './UploadFileProcessing';
import NeedResponse from './NeedResponse';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Typography } from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers/icons';
import moment from 'moment';

type Props = {
    studentName: string;
    studentId: number;
    schoolName: string;
    schoolId: number | null;
    needs: Need[];
    onClose: () => void;
}


const StudentUploadMultipleModal = ({ onClose, studentName, studentId, schoolName, schoolId, needs }: Props) => {
    const [success, setSuccess] = useState(false);
    const [selectedNeeds, setSelectedNeeds] = useState<number[]>([]);
    const [needsCategory, setNeedsCategory] = useState<string>();

    const handleCheckboxChange = (id: number) => {
        if (selectedNeeds.includes(id)) {
            setSelectedNeeds(selectedNeeds.filter((needId) => needId !== id));
        } else {
            setSelectedNeeds([...selectedNeeds, id]);
        }
    };

    moment.locale('en-us');
    const formatDate = (dateString: string) => {
        const date = moment.utc(dateString);
        const formatedDate = date.format("MMM DD");
        return formatedDate;

    };

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    useEffect(() => {
        if (needs.length == 1) {//default checkbox to checked when there is only 1 need.
            handleCheckboxChange(needs[0].id);
        }
        setNeedsCategory(needs[0].type);
    }, []);


    return (
        <ModalOverlay onClick={onClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>{studentName} #{studentId} Upload</ModalHeader>
                <CloseButton onClick={onClose} />
                <ModalBody>
                    {success ?
                        (<div style={{ color: '#5EB234' }}>File Successfully Uploaded!</div>)
                        :
                        (<div>
                       
                            <div style={{ textAlign: 'left' }}>
                                {needs.map((need) => (
                                    <>
                                     

                                        <Accordion expanded={expanded === 'panel' + need.id} onChange={need.uploaded ? handleChange('panel' + need.id) : handleChange('')}  >
                                                <AccordionSummary
                                                    expandIcon={need.uploaded && <ArrowDropDownIcon />}
                                                    aria-controls="panel2-content"
                                                    id="panel2-header"
                                                style={need.uploaded ? { cursor: 'pointer' } : { cursor: 'default' }}>


                                                {need.type == "FA" &&
                                                    <div key={need.id} onClick={(e) => { e.stopPropagation() }} style={{ display: 'flex', padding: '5px' }}>

                                                        <Checkbox id={`need-${need.id}`} checked={selectedNeeds.includes(need.id)}
                                                            name={'needCheckbox'} onChange={() => { handleCheckboxChange(need.id) }}
                                                            sx={{
                                                                color: '#7F96FF', '&.Mui-checked': { color: '#7F96FF', }
                                                            }} />
                                                        <label style={{ paddingLeft: '10px', paddingRight: '15px', paddingTop: '10px', width: '100%' }} htmlFor={`need-${need.id}`}>
                                                            <div>
                                                                <span style={{ color: '#7F96FF', marginRight: '5px' }}>{formatDate(need.needDate)} </span>
                                                                <span style={{ color: 'black' }}>   {need.need}</span>
                                                            </div>
                                                        </label>

                                                    </div>}

                                                {need.type == "Academic" &&
                                                    <div key={need.id} onClick={(e) => { e.stopPropagation() }} style={{ display: 'flex', padding: '5px' }}>

                                                        <Checkbox id={`need-${need.id}`} checked={selectedNeeds.includes(need.id)}
                                                            name={'needCheckbox'} onChange={() => { handleCheckboxChange(need.id) }}
                                                            sx={{
                                                                color: '#BA9976', '&.Mui-checked': { color: '#BA9976', }
                                                            }} />
                                                        <label style={{ paddingLeft: '10px', paddingRight: '15px', paddingTop: '10px', width: '100%' }} htmlFor={`need-${need.id}`}>
                                                            <div>
                                                                <span style={{ color: '#BA9976', marginRight: '5px' }}>{formatDate(need.needDate)} </span>
                                                                <span style={{ color: 'black' }}>   {need.need}</span>
                                                            </div>
                                                        </label>

                                                    </div>}

                                                {need.type == "Tuition" &&
                                                    <div key={need.id} onClick={(e) => { e.stopPropagation() }} style={{ display: 'flex', padding: '5px' }}>

                                                        <Checkbox id={`need-${need.id}`} checked={selectedNeeds.includes(need.id)}
                                                            name={'needCheckbox'} onChange={() => { handleCheckboxChange(need.id) }}
                                                            sx={{
                                                                color: '#253872', '&.Mui-checked': { color: '#253872', }
                                                            }} />
                                                        <label style={{ paddingLeft: '10px', paddingRight: '15px', paddingTop: '10px', width: '100%' }} htmlFor={`need-${need.id}`}>
                                                            <div>
                                                                <span style={{ color: '#253872', marginRight: '5px' }}>{formatDate(need.needDate)} </span>
                                                                <span style={{ color: 'black' }}>   {need.need}</span>
                                                            </div>
                                                        </label>

                                                    </div>}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <NeedResponse needId={need.id} />
                                                </AccordionDetails>
                                            </Accordion>
                                           

                                    </>
                                ))}
                            </div>
                            <br /><br />


                            <br /><br />
                            <UploadFileProcessing
                                uploadPath={`api/document/uploadStudentNeedDocument/${studentId}/${schoolId}?${selectedNeeds.map((id) => `needIds=${id}`).join('&')}`}
                                onSuccess={() => setSuccess(true)}
                                acceptedFileTypes={{
                                    'application/pdf': ['.pdf'],
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                                    'text/plain': ['.txt'],
                                }}
                                selectedNeeds={selectedNeeds}

                            />

                        </div>)
                    }
                </ModalBody>
            </ModalContent>
        </ModalOverlay>
    );
};

export default StudentUploadMultipleModal;

import React, { useState } from 'react';
import { CompleteStatusBodyCell, IncompleteStatusBodyCell, OverdueStatusBodyCell, ToDoStatusBodyCell, UploadedStatusBodyCell } from './ProjectsStyles';
import IncompleteCommentIcon from './IncompleteCommentIcon';
import IncompleteCommentModal from './IncompleteCommentModal';
import { Note } from '../../globalTypes/objects';
import axios from 'axios';
import { useToken } from '../../useToken';
import { API_BASE_URL } from '../../urlConfig';


type Props = {
    status: string;
    projectDescription: string;
    projectId: number;
    projectNotes: Note[];
    isCard: boolean;
    onStatusClick: () => void;

}

const StatusCell = ({ status, projectId, projectNotes, projectDescription, onStatusClick, isCard }: Props) => {

    const [selectedNotes, setSelectedNotes] = useState<Note[]>([]);
    const updateNoteAndOpenModal = (notes: Note[], callback: () => void) => {
        setSelectedNotes(notes);
        callback();
    };

    const onNoteModalClose = async () => {
        setSelectedNotes([]);
        // await loadData();
    }

    const onNoteModalOpen = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        setSelectedNotes(projectNotes);
        addProjectNoteUserLog();
    }

  //  const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const getToken = useToken();
    const addProjectNoteUserLog = async () => {
        const localToken = await getToken();

        axios.post(`${API_BASE_URL}/api/project/addProjectNoteUserLog/${projectId}`, { TimelineNotes: projectNotes }
            , {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            })
            //.then(() => {
            //    console.log( "hit here");
            //  //  setResponse("Successfully updated user schools.");

            //})
            .catch(error => {
                console.log(error?.response?.data);

            })
    };


    if (status == 'Complete') {


        return (
            <CompleteStatusBodyCell onClick={onStatusClick}>
                {status}
            </CompleteStatusBodyCell>
        );
    }
    else if (status == 'Incomplete') {

        const wasNotRead = projectNotes.some(note => note != null && !note.noteRead);
        return (
            <>
                {
                    selectedNotes.length > 0 && <IncompleteCommentModal onClose={onNoteModalClose} notes={selectedNotes} projectDescription={projectDescription} />
                }
                <div style={{ display: 'flex' }}>  <IncompleteStatusBodyCell onClick={onStatusClick}>
                    {status}   </IncompleteStatusBodyCell>
                    <div style={{
                        zIndex: '2', marginLeft: '-25px', marginTop: '-2px'
                    }}
                        onClick={(e) => { onNoteModalOpen(e) }}
                    > {projectNotes.length > 0 && !isCard && <IncompleteCommentIcon wasNotRead={wasNotRead} />}</div>
                </div>
            </>);
    }
    else if (status == 'Overdue') {


        return (
            <OverdueStatusBodyCell onClick={onStatusClick}>
                {status}
            </OverdueStatusBodyCell>
        );
    }
    else if (status == 'Uploaded') {


        return (
            <UploadedStatusBodyCell onClick={onStatusClick}>
                {status}
            </UploadedStatusBodyCell>
        );
    }
    else {//ToDo need type
        return (
            <ToDoStatusBodyCell onClick={onStatusClick}>
                {status}
            </ToDoStatusBodyCell>
        );
    }
};
export default StatusCell;

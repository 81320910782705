import styled from "@emotion/styled";


export const FeedbackDate = styled.div`
color: var(--Black, #000);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;


export const ModalOverlay = styled.div`
 position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent grey background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:5;


`;

export const ModalHeader = styled.div`
background-color:rgba(131, 151, 248, 0.15);
padding-top:15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
 width: 435px;
      border-radius: 10px;
      font-weight:700;
color:#253872;
font-size:16px;
height:40px;
  @media (max-width:800px){
        min-width: 300px;
           max-width: 350px;
    }
 `;
export const ModalContent = styled.div`
  background: #fff;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
 width: 435px;
      border-radius: 10px;
      @media (max-width:800px){
        min-width: 300px;
           max-width: 350px;
    }
       
 `;
export const ModalBody = styled.div`
  background: #fff;
  padding-top: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  width: 435px;

  min-height: 300px;
     border-bottom-left-radius: 10px;
     border-bottom-right-radius: 10px;
     max-height:500px;
       overflow-y:auto;
       @media (max-width:800px){
        min-width: 300px;
           max-width: 350px;
    }
 `;

export const CloseButton = styled.button`
 position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  background: none;
  border: none;
  outline: none;
  color: #000;
`;

export const FilterOption = styled.div`
border-radius: 4px;
background: rgba(131, 151, 248, 0.07);
display: inline-flex;
padding: 5px 10px;
justify-content: center;
align-items: center;
gap: 10px;
margin-right:8px;
margin-top:8px;
cursor:pointer;
`;

export const FilterButton = styled.div`

margin-top:35px;
cursor:pointer;
`;
import React from 'react';
import {  ModalBody, ModalContent, ModalHeader, ModalOverlay } from '../../src/components/Processing/ProcessingStyles';
import SchoolSelector from './SchoolSelector';
/*import { Button } from './Button';*/
import Button from '@mui/material/Button';
import { CloseButton } from './CloseButton';

type Props = {
    onClose: () => void;
}

const DownloadFormsModal = ({ onClose }: Props) => {
    return (
        <ModalOverlay onClick={onClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
             <ModalHeader>School Selector</ModalHeader>
            <CloseButton onClick={onClose} />
              <ModalBody>
                     
                    <div>Please Select School:</div>
                    <br />
                    <SchoolSelector onClose={() => "school updated"} />
                    <div style={{ padding:'40px' }}>
                   <Button onClick={onClose} variant="outlined"  style={{ color: '#253872' }}>Ok
                    </Button>
                   </div>
              
                    </ModalBody>
            </ModalContent>
        </ModalOverlay>
    );
};

export default DownloadFormsModal;

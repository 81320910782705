import React, { useState,useEffect } from 'react';
import styled from '@emotion/styled';
import '../../styles.css';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ViewAllButton } from '../ViewAllButton';
import axios from 'axios';
import { useToken } from '../../useToken';
import { useAppSelector } from '../../store';
import { Notification } from '../../globalTypes/objects';
import noticeIcon from '../../Images/noticeAlertIcon.svg';
import NotificationItem from './NotificationItem';
import { API_BASE_URL } from '../../urlConfig';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontSize: '16px',
    //  border: 0
    color: 'black',
}));


const NotificationsCard = () => {
    const schoolState = useAppSelector((state) => state.school);
    const selectedSchoolId = schoolState.school.id;
    const [notifications, setNotifications] = useState<Notification[]>();
    const [error, setError] = useState<string>('')
   // const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const getToken = useToken();

    const getNotifications = async () => {
        try {
            const localToken = await getToken();
            const { data } = await axios.get(`${API_BASE_URL}/api/Notification/getNotifications/${selectedSchoolId}`, {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setNotifications(data);

        } catch (error: any) {
            
            setError(error.message);
        }
    };
    
    useEffect(() => {
        getNotifications().catch(error => {
            setError('Error getting notifications:'+ error);
        });
    }, []);
    return (
        <>
            <div className="dashboardHeader">Notifications</div>
            <div className="dashboardBody">
                <TableContainer sx={{
                    width: '100%', minHeight: '320px', maxHeight: '320px', overflowY: 'auto', padding: '0px', background: 'white',
                }}>
                    <Table><TableBody>
                        {notifications && notifications.map(notice => (
                            <TableRow key={self.crypto.randomUUID()}>
                                <StyledTableCell key={self.crypto.randomUUID()} >
                                    <NotificationItem noticeText={notice.notificationText} ></NotificationItem>
                                </StyledTableCell>
                        </TableRow>
                        ))}
                        {!notifications && <TableRow>
                            <TableCell>No New Notifications!</TableCell>
                        </TableRow>}
                     
                    </TableBody>
                    </Table>
                </TableContainer>
              {/*  <ViewAllButton navigateTo="/announcements" />*/}
            </div>
        </>
    )


};

export default NotificationsCard;
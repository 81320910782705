import React, { useEffect, useState } from 'react';
import {  ModalBody, ModalContent, ModalHeader, ModalOverlay } from '../Processing/ProcessingStyles';
import { CloseButton } from '../CloseButton';
import SchoolSelector from '../SchoolSelector';
import MultipleSchoolSelector from './MultipleSchoolSelector';
import { Button } from '@mui/material';
import { useToken } from '../../useToken';
import { MultiValue } from 'react-select';
import { Department, School } from '../../types';
import axios from 'axios';
import { API_BASE_URL } from '../../urlConfig';
import MultipleDepartmentSelector from './MultipleDepartmentSelector';

type Props = {
    userId: string;
    userName: string;
    onClose: () => void;
    selectedDepartments: Department[];
}

const AddDepartmentModal = ({ onClose, userId, userName, selectedDepartments }: Props) => {
    const getToken = useToken();

    const [response, setResponse] = useState<string>('');
    const AddDepartments = async () => {
        const departmentIdList: number[] = [];
        selectedDepartments.map((dep: Department) => departmentIdList.push(dep.departmentId));
      
        if (selectedDepartments.length > 0) {
            const localToken = await getToken();
            
            axios.post(`${API_BASE_URL}/api/admin/AddUserDepartment/${userId}`, { DepartmentIdList: departmentIdList }
                , {

                    headers:
                    {
                        'Authorization': `Bearer ${localToken?.accessToken}`
                    }
                })
                .then(() => {
                    setResponse("Successfully updated user departments.");
                   
                })
                .catch(error => {
                    setResponse(error?.response?.data);
                    
                })
        } else {
            setResponse("Please select a Department.");
        }
    }
    useEffect(() => {
        console.log(selectedDepartments);
    }, [selectedDepartments]);

    return (
        <ModalOverlay onClick={onClose}>
          
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>Update Departments</ModalHeader>

                <CloseButton onClick={onClose} />
                <ModalBody style={{ height:'900px'} }>
                    Select Department to add to {userName}
                    <br />
                    <MultipleDepartmentSelector selectedDepartments={selectedDepartments} />
                    <Button style={{ marginTop: '205px', backgroundColor:"#253872" }} variant='contained' onClick={() => (AddDepartments())}>Update User Departments</Button>
                    {response && <div>{response}</div>}
                </ModalBody>
            </ModalContent>
        </ModalOverlay>
    );
};

export default AddDepartmentModal;

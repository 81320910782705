import React, { useState } from 'react';
import styled from '@emotion/styled';
import commentIcon from '../../Images/CommentPurpleIcon.svg';



const Icon = styled.div`
width: 40px;

max-width: 100%;
overflow: hidden;
height: 40px;
margin-top: 8px;
margin-left: 10px;
`;


const CommentIcon = () => {

    return (
        <Icon>

            <img width={'30px'} height={'30px'} src={commentIcon} alt="Comment Icon" />
         </Icon>
    );
};
export default CommentIcon;

import React, { useContext, useEffect, useState } from 'react';
import { API_BASE_URL } from '../../urlConfig';
import { Attachments } from './ProjectsStyles';
import { useToken } from '../../useToken';
import axios from 'axios';
import { Form } from '../../globalTypes/objects';
import { Table, TableBody, TableCell, TableContainer, TableRow, styled } from '@mui/material';
import garbageIcon from '../../Images/garbageIcon.svg';

type Props = {
    projectId?: number | null;
    schoolId?: number | null;

}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontSize: '13px',
    color: 'black',
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({

   // '&:nth-of-type(even)': {
        backgroundColor: '#fff',
   // },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const ProjectReportAttachments = ({ projectId, schoolId }: Props) => {
    const [attachments, setAttachments] = useState<Form[]>();
    const [error, setError] = useState<string>('');
    const getToken = useToken();
    const getAttachments = async () => {
        try {
            const localToken = await getToken();

            const { data } = await axios.get(`${API_BASE_URL}/api/Project/getAttachments/${schoolId}/${projectId}`, {
                headers:
                {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setAttachments(data);
            setError("");

        } catch (error: any) {
   
            setError("Error occurred while getting current attachments " + error.message);
        }
    };

    const deleteAttachment = async (attachmentId: number) => {
           const localToken = await getToken();

        axios.post(`${API_BASE_URL}/api/Project/deleteAttachments`, { Id: attachmentId }, {
            headers:
            {
                'Authorization': `Bearer ${localToken?.accessToken}`
            }
        })
            .then(() => {
                getAttachments();
                setError("");

            })
            .catch(error => {
                 setError("Error occurred while getting deleting attachments" + error?.response);
            });      
    };

    useEffect(() => {
        getAttachments();
    }, [projectId, schoolId]);
    return (

        <Attachments>
            {attachments && attachments?.length > 0 ?
                <>  Current Attachements:
                    <TableContainer sx={{
                        marginTop:'15px'                   
                    }}>
                        <Table>
                            <TableBody>
                                {attachments && attachments.map(attachment => (
                                    <StyledTableRow key={attachment.id} >
                                    <StyledTableCell sx={{ width:'25px' }} >
                                        <img style={{ cursor: 'pointer' }} title={"Delete File"} src={garbageIcon} onClick={() => { deleteAttachment(attachment.id) }} />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {attachment.fileName}
                                    </StyledTableCell>
                                </StyledTableRow>

                            ))}</TableBody>
                        </Table>
                    </TableContainer></> : <i>No attachments found!</i>}
            {error && error != "" && <div style={{ color:'red' }}>{error}</div>}
        </Attachments>
    );

};

export default ProjectReportAttachments;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import { Need } from '../../globalTypes/objects';
import SubmitButton from '../SubmitButton';
import { useAuth0 } from "@auth0/auth0-react";
import { useToken } from "../../useToken";
import NeedItem from './NeedItem';
import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import { API_BASE_URL } from '../../urlConfig';
import { ConfirmText } from '../ConfirmText';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox } from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers/icons';
import moment from 'moment';
import NeedResponse from './NeedResponse';

const Input = styled.input`
  padding: 10px;
  font-size: 18px;
  width: 355px;
  box-sizing: border-box;
`;

type Props = {
    studentName: string;
    studentId: number;
    schoolName: string;
    schoolId: number;
    needs: Need[];
    /* onClose: () => void;*/
}

const AddNote = ({ studentName, studentId, schoolName, schoolId, needs }: Props) => {
    const [note, setNote] = useState('');
    const [loading, setLoading] = useState(false);
    const [invalidNote, setInvalidNote] = useState(false);
    const [success, setSuccess] = useState(false);
    const [response, setResponse] = useState('');
    const [needRequired, setNeedRequired] = useState(false);

    const [selectedNeeds, setSelectedNeeds] = useState<number[]>([]);

    moment.locale('en-us');
    const formatDate = (dateString: string) => {
        const date = moment.utc(dateString);
        const formatedDate = date.format("MMM DD");
        return formatedDate;

    };

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    // const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const handleCheckboxChange = (id: number) => {
        if (selectedNeeds.includes(id)) {
            setSelectedNeeds(selectedNeeds.filter((needId) => needId !== id));
        } else {
            setSelectedNeeds([...selectedNeeds, id]);
        }
    };



    const handleNoteChange = (event: { target: { value: any; }; }) => {
        const inputValue = event.target.value;
        setNote(inputValue);

        if (inputValue.length >= 400) {
            setInvalidNote(true);
        } else {
            setInvalidNote(false);
        }
    };
    const getToken = useToken();

    const handleAddNote = async () => {
        if (selectedNeeds.length > 0) {
            const localToken = await getToken();
            setNeedRequired(false);
            setLoading(true);
            axios.post(`${API_BASE_URL}/api/note/AddNoteForNeedId/${schoolId}/${studentId}`, { Note: note, Type: needs[0].type, NeedIdList: selectedNeeds }
                , {

                    headers:
                    {
                        'Authorization': `Bearer ${localToken?.accessToken}`
                    }
                })
                .then(() => {
                    console.log(response);
                    setSuccess(true);
                    setLoading(false);
                })
                .catch(error => {
                    setResponse(error?.response?.data);
                    setLoading(false);
                })
        } else {
            setNeedRequired(true);
        }
    }
    useEffect(() => {
        if (needs.length == 1) {//default checkbox to checked when there is only 1 need.
            handleCheckboxChange(needs[0].id);
        }
    }, []);

    return (
        <div style={{ width: '100%' }}>

            {success ?
                (<div style={{ color: '#5EB234' }}>Comment Added Successfully!</div>)
                :
                (<><div style={{ textAlign: 'left' }}>
                    {needs.map((need) => (
                  
                        <Accordion expanded={expanded === 'panel' + need.id} onChange={need.uploaded ? handleChange('panel' + need.id) : handleChange('')}  >
                            <AccordionSummary
                                expandIcon={need.uploaded && <ArrowDropDownIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                                style={need.uploaded ? { cursor: 'pointer' } : { cursor: 'default' }}>
                                {need.type == "FA" && <div key={need.id} onClick={(e) => { e.stopPropagation() }} style={{ display: 'flex', padding: '5px' }}>

                                    <Checkbox id={`need-${need.id}`} checked={selectedNeeds.includes(need.id)}
                                        name={'needCheckbox'} onChange={() => { handleCheckboxChange(need.id) }}
                                        sx={{
                                            color: '#7F96FF', '&.Mui-checked': { color: '#7F96FF', }
                                        }} />
                                    <label style={{ paddingLeft: '10px', paddingRight: '15px', paddingTop: '10px', width: '100%' }} htmlFor={`need-${need.id}`}>
                                        <div>
                                            <span style={{ color: '#7F96FF', marginRight: '5px' }}>{formatDate(need.needDate)} </span>
                                            <span style={{ color: 'black' }}>   {need.need}</span>
                                        </div>
                                    </label>
                                   
                                </div>}
                                {need.type == "Academic" && <div key={need.id} onClick={(e) => { e.stopPropagation() }} style={{ display: 'flex', padding: '5px' }}>

                                    <Checkbox id={`need-${need.id}`} checked={selectedNeeds.includes(need.id)}
                                        name={'needCheckbox'} onChange={() => { handleCheckboxChange(need.id) }}
                                        sx={{
                                            color: '#BA9976', '&.Mui-checked': { color: '#BA9976', }
                                        }} />
                                    <label style={{ paddingLeft: '10px', paddingRight: '15px', paddingTop: '10px', width: '100%' }} htmlFor={`need-${need.id}`}>
                                        <div>
                                            <span style={{ color: '#BA9976', marginRight: '5px' }}>{formatDate(need.needDate)} </span>
                                            <span style={{ color: 'black' }}>   {need.need}</span>
                                        </div>
                                    </label>

                                </div>}

                                {need.type == "Tuition" && <div key={need.id} onClick={(e) => { e.stopPropagation() }} style={{ display: 'flex', padding: '5px' }}>

                                    <Checkbox id={`need-${need.id}`} checked={selectedNeeds.includes(need.id)}
                                        name={'needCheckbox'} onChange={() => { handleCheckboxChange(need.id) }}
                                        sx={{
                                            color: '#253872', '&.Mui-checked': { color: '#253872', }
                                        }} />
                                    <label style={{ paddingLeft: '10px', paddingRight: '15px', paddingTop: '10px', width: '100%' }} htmlFor={`need-${need.id}`}>
                                        <div>
                                            <span style={{ color: '#253872', marginRight: '5px' }}>{formatDate(need.needDate)} </span>
                                            <span style={{ color: 'black' }}>   {need.need}</span>
                                        </div>
                                    </label>

                                </div>}
                                                </AccordionSummary>
                            <AccordionDetails>
                                <NeedResponse needId={need.id} />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>

                    {needRequired && (
                        <div>
                            <p style={{ color: 'red' }}>Please select 1 or more items to associate with the comment.</p>
                        </div>
                    )}
                    <div style={{ paddingTop: '50px' }}>
                        <TextField
                            id="outlined-multiline-flexible"
                            label="Add Comment Here"
                            multiline rows={3}
                            onChange={handleNoteChange} fullWidth
                        />
                    </div></>)}


            {invalidNote && (
                <div>
                    <p style={{ color: 'red' }}>Comment is too long</p>
                </div>
            )}

            {response && (
                <div>
                    <p style={response == "Success" ? { color: 'green' } : { color: 'red', width: '600px' }}>Response: {response}</p>
                </div>
            )}

            {note && !success && (
                <div style={{
                    height: '60px', paddingTop: '15px', textAlign: 'center'
                }}>


                    <ConfirmText />
                    <Button onClick={handleAddNote} variant="contained" disabled={loading || invalidNote} style={{ backgroundColor: '#253872' }}>Submit
                    </Button>
                </div>
            )}
        </div>
    );
};

export default AddNote;
import React, { useState } from 'react';
import styled from '@emotion/styled';
import nextIcon from '../Images/NextIcon.svg';
import { IconButton } from '@mui/material';

type Props = {
    onClick: () => void;
    showNext: boolean;
}

const Wrapper = styled.div`
cursor:pointer;
display:flex`;



const Icon = styled.img`
width: 28px;

overflow: hidden;
height: 28px;

`;

const IconText = styled.div`
margin-top: 12px;
padding-left:2px;
padding-right:2px;

`;
const NextIcon = ({ onClick, showNext }: Props) => {

    return (
        <Wrapper>
            {showNext && <IconText onClick={onClick}>Next</IconText>}
            <IconButton onClick={onClick}> <Icon src={nextIcon} alt="Next" /></IconButton>

        </Wrapper>
    );
};
export default NextIcon;

import React from "react";
import '../styles.css';
import styled from '@emotion/styled';


const Text = styled.div`
color: #B1B1B1;
font-family: "Open Sans";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;


export const ConfirmText = (): JSX.Element => {
    return (
        <Text>
            By clicking submit I confirm that the information provided is accurate. 
        </Text>
    );
};
import React, { useState,useEffect } from 'react';
import styled from '@emotion/styled';
import alertNoticeIcon from '../../Images/noticeAlertIcon.svg';
import completeNoticeIcon from '../../Images/noticeCheckIcon.svg';
import commentNoticeIcon from '../../Images/noticeCommentIcon.svg';
import overdueNoticeIcon from '../../Images/noticeOverdueIcon.svg';
import parse from 'html-react-parser';

type Props = {
    noticeText: string;
   }


const Wrapper = styled.div`
display:flex;
`;

const Icon = styled.div`
width:40px
`;

const NoticeText = styled.div`
margin-top:8px;
margin-left:10px;
`;


const NotificationItem = ({ noticeText }: Props) => {


    return (<Wrapper>
        <Icon>
            {noticeText.indexOf('Complete') > -1 && <img src={completeNoticeIcon} />}
            {noticeText.indexOf('Incomplete') > -1 && <img src={alertNoticeIcon} />}
            {noticeText.indexOf('Comment') > -1  && <img src={commentNoticeIcon} />}
            {noticeText.indexOf('Overdue') > -1  && <img src={overdueNoticeIcon} />}
        </Icon> 
            
        <NoticeText>{parse( noticeText)}</NoticeText> 
    </Wrapper>
        
    );
};
export default NotificationItem;

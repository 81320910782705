import styled from "@emotion/styled";

export const Wrapper = styled.div`
width: 100%;
`;


export const EditContactTable = styled.div`
border-radius: 4px;
    border: 1px solid var(--Outlines, rgba(37, 56, 114, 0.14));
    border-bottom: 0px solid var(--Outlines, rgba(37, 56, 114, 0.14));
    box-shadow: 0px 0px 20px 0px rgba(131, 151, 248, 0.05);
    color: black;
    font-size:14px;
    margin-top:24px;
    height:400px;
    width:800px;
    max-height:400px;
    overflow:scroll;
`;

export const EditContactRow = styled.div`
    display:flex;
    border-bottom: 1px solid var(--Outlines, rgba(37, 56, 114, 0.14));
    color: black;
    font-size:14px;
`;

export const EditContactNameCell = styled.div`
   width:75%;
    text-align: left; 
    padding: 10px;
`;


export const EditContactDepartmentCell = styled.div`
    border-left: 1px solid var(--Outlines, rgba(37, 56, 114, 0.14));
     width:80%;
        text-align: left;
        padding: 10px;
    
`;

export const EditContactDeleteCell = styled.div`
     width:10%;
     padding: 10px;
`;

export const AddContactDiv = styled.div`
   display:flex;
`;


export const ModalOverlay = styled.div`
 position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent grey background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:5;


`;

export const ModalHeader = styled.div`
background-color:rgba(131, 151, 248, 0.15);
padding-top:15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  min-width: 435px;
      border-radius: 10px;
      font-weight:700;
color:#253872;
font-size:16px;
height:40px;
  @media (max-width:800px){
        min-width: 300px;
           max-width: 350px;
    }
 `;
export const ModalContent = styled.div`
  background: #fff;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  min-width: 435px;
      border-radius: 10px;
      @media (max-width:800px){
        min-width: 300px;
           max-width: 350px;
    }
       
 `;
export const ModalBody = styled.div`
  background: #fff;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  min-width: 435px;
  max-width: 600px;
  min-height: 600px;
     border-bottom-left-radius: 10px;
     border-bottom-right-radius: 10px;
     max-height:1000px;
       overflow-y:auto;
       @media (max-width:800px){
        min-width: 300px;
           max-width: 350px;
    }
 `;


export const Footer = styled.div`
display: flex;
width: 85%;
max-width: 1400px;
overflow:auto; 

 `;


export const HigherEdInfo = styled.div`
display: flex;
margin-top: 70px;
height: 150px;
width:45%;
margin-bottom:30px;
 border-radius: 10px;
background-color: #fff;
box-shadow: 0px 0px 20px rgba(131, 151, 248, 0.13);
 `;
export const HigherEdInfoName = styled.div`
color: var(--Dark-Blue, #253872);
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
 `;

export const HigherEdInfoNumAddress = styled.div`
color: #253872;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-left: 55px;
padding-top: 4px;
margin-top:20px;
margin-left:30%;
 `;
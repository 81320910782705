import React, { useEffect, useState } from 'react';
import styled from "@emotion/styled";
import { FaDownload } from "react-icons/fa6";
import axios from 'axios';
import { Form, Note } from '../../globalTypes/objects';
import saveAs from 'file-saver';
import { useToken } from "../../useToken";
import DownloadIcon from '../Processing/DownloadIcon';
import { API_BASE_URL } from '../../urlConfig';
import { Typography } from '@mui/material';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { CommentCreatedBy, CommentDate, CommentInfo, IncompleteComment } from '../Processing/ProcessingStyles';
import CommentIcon from './CommentIcon';

const OuterWrap = styled.div`
  border-radius: 5px;
  border: 2px solid #b5b5b8;
  padding: 40px 24px;
  width: 250px;
`;

const InnerStyles = styled.div`
 display:flex;
 height: 45px;
 margin-top: 8px;
 margin-bottom: 8px;
 align-items:center;
 cursor:pointer;
 border-radius: 4px;
background: var(--Light-Blue-Background, rgba(131, 151, 248, 0.07));
`


const ModalHeader = styled.div`
background-color:#8397F826;
font-weight:700;
color:#253872;
font-size:16px;
align-items:center;

`;



type Props = {
    needId: number;
}

const NeedResponse = ({ needId }: Props) => {
    const [documents, setDocuments] = useState<Form[]>([]);
    const [comments, setComments] = useState<Note[]>([]);
    const [error, setError] = useState<string>("");

    // const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const getToken = useToken();
    const getNeedDocuments = async () => {
        try {
            const localToken = await getToken();
            const url = API_BASE_URL;
            const { data } = await axios.get(`${API_BASE_URL}/api/Processing/getNeedSubmittedDocuments/${needId}`, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setDocuments(data);
        } catch (error: any) {
            //console.log(error.message);
            setError(error.message || 'An unknown error occurred');
        }
    };

    const getNeedComments = async () => {
        try {
            const localToken = await getToken();
            const url = API_BASE_URL;
            const { data } = await axios.get(`${API_BASE_URL}/api/Processing/getNeedSubmittedComments/${needId}`, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setComments(data);
        } catch (error: any) {
            //console.log(error.message);
            setError(error.message || 'An unknown error occurred');
        }
    };

    const handleDocumentDownload = async (form: Form) => {
        try {
            const localToken = await getToken();
            //   const baseUrl = process.env.REACT_APP_API_BASE_URL;
            logDownloadDocument(form.id);
            const response = await axios.get(`${API_BASE_URL}/api/Document/DownloadNeedSchoolSubmittedDocument/${form.id}`, {
                responseType: 'arraybuffer',
                headers: {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });


            const blob = new Blob([response.data], { type: 'application/octet-stream' });
            saveAs(blob, form.fileName);
        } catch (e) {
            console.error('Download failed', e);
            alert('Download failed   ' + e.message);
        }

    };

    const logDownloadDocument = async (formId: number) => {
        const localToken = await getToken();
        axios.post(`${API_BASE_URL}/api/Logger/addDownloadSchoolSubmittedProjectLog/${formId}`, {}
            , {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            })
            //.then(() => {
            //    console.log( "hit here");
            //  //  setResponse("Successfully updated user schools.");

            //})
            .catch(error => {
                console.log(error?.response?.data);

            })
    };

    momentTz.locale('en-us');
    // momentTz.tz.setDefault("America/New_York"); 
    const formatDate = (dateString: string) => {
        const date = momentTz.utc(dateString);//.subtract(4, 'hours');
        const estDate = date.tz('America/New_York');
        const formatedDate = estDate.format("MMM DD h:mm a");
        return formatedDate;
    };

    useEffect(() => {
        getNeedDocuments();
        getNeedComments();
    }, []);

    return (
        <div>
            {documents.length > 0 &&
                <div style={{ marginBottom: '24px', }}>

                    {documents.map(
                        (document) =>
                            <IncompleteComment onClick={() => handleDocumentDownload(document)}>

                                <div style={{ width: '15%', marginTop: '8px' }}> <DownloadIcon /></div>
                                <div style={{ width: '50%', paddingTop: '15px', overflowX: 'clip', wordWrap: 'break-word', paddingLeft: '5px', fontSize: '13px', textAlign: 'left', color: 'black' }}>{document.fileName}</div>
                                <CommentDate> <CommentCreatedBy> {document.uploadedBy}</CommentCreatedBy>{document.uploadedAt ? formatDate(document.uploadedAt) : ''}</CommentDate>
                            </IncompleteComment>
                    )}</div>}
            {comments.length > 0 &&
                <>

                    {
                        comments.map(
                            (comment) =>
                                <>  <div style={{ display: 'flex' }}>
                                </div>
                                    <IncompleteComment>
                                        <div style={{ width: '15%' }}> <CommentIcon /></div>
                                        <div style={{ width: '50%', paddingTop: '15px', paddingLeft: '5px', fontSize: '13px', color: 'black' }}>{comment.note}</div>

                                        <CommentDate> <CommentCreatedBy> {comment.createdBy}</CommentCreatedBy>{comment.dateCreated ? formatDate(comment.dateCreated) : ''}</CommentDate>
                                    </IncompleteComment>
                                </>
                        )
                    }</>}
            {error &&
                <div style={{ color: '#FB4A4A', margin: '8px' }}>
                    {error}
                </div>
            }
        </div>


        //<OuterWrap>

        //</OuterWrap>
    );
};

export default NeedResponse;

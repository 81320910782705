import React, { useContext, useEffect, useState, ChangeEvent } from 'react';
/*import SelectedSchoolContext from './SelectedSchoolContext';*/
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useToken } from '../../useToken';
import Select, { InputActionMeta, MultiValue, SingleValue } from 'react-select';
import { setSchoolState } from '../../store/schoolSlice';
import { useAppSelector, useAppDispatch } from "../../store/index";
import makeAnimated from 'react-select/animated';
import { Department, School } from '../../types';
import { API_BASE_URL } from '../../urlConfig';

type Props = {
    selectedDepartments: Department[];
}




const customStyles = {
    container: (provided: any) => ({
        ...provided,
        marginTop: '24px',
        textAlign:'left',
        backgroundColor: 'white'
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        boxShadow: state.isFocused && '1px solid #a1a8ca',
        border: state.isFocused ? '1px solid #a1a8ca' : '1px solid #e5e5ea',
   
        '&:hover': {
            border: state.isFocused && '1px solid #a1a8ca',
        },
    }),
};
const MultipleDepartmentSelector = ({selectedDepartments }: Props) => {

    const [departments, setDepartments] = useState<Department[]>([]);
    const additionalSelectedDepartments: (string | MultiValue<{ label: string; value: number; }>)[] = [];
    const [error, setError] = useState('');
    const getToken = useToken();




    const getDepartments = async () => {
        try {
            const localToken = await getToken();

            const { data } = await axios.get(`${API_BASE_URL}/api/Admin/getDepartments`, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setDepartments(data);
        } catch (error: any) {
            setError(error.message || 'An unknown error occurred');
        }
    };


    // Fetch the departments from your API
    useEffect(() => {
        getDepartments();
    }, [selectedDepartments]);

    const handleDepartmentsChange = (event: MultiValue<{ label: string, value: number }>) => {
        event.map((x) => {
            if (selectedDepartments.filter(n => n.departmentId == x.value).length < 1) {
                const newDept: Department = { departmentId: x.value, departmentName: x.label }
                selectedDepartments.push(newDept);
            }
           
        });

        selectedDepartments.map((x,index) => {
            const selecterIndex = event.filter(n => n.value == x.departmentId).length;
            if (selecterIndex < 1 ) {
                selectedDepartments.splice(index, 1);
            }          
        })
       
       additionalSelectedDepartments.push(event);
        
    };
   
    const animatedComponents = makeAnimated();
    return (
        <>   {departments && <Select
            options={departments?.map(dep => ({
                label: dep.departmentName,
                value: dep.departmentId,

            }))}
            components={animatedComponents}
            onChange={(event) => handleDepartmentsChange(event)}
            styles={customStyles}
            isMulti={true}
            placeholder="Select Department" defaultValue={selectedDepartments.map(dep => ({
                label: dep.departmentName ?? "",
                value: dep.departmentId,

            }))}
        ></Select>
            

        }</>

    );
}

export default MultipleDepartmentSelector;
import React, { useContext, useEffect, useState } from 'react';
import { StudentFile, StudentFolder } from '../../globalTypes/objects';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useAuth0 } from "@auth0/auth0-react";
import { Elipse,HeaderElipse, InactiveTag, StudentId, StudentName } from './FoldersStyles';
import '../../styles.css';
import { Box, Card, Checkbox, CircularProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import filterIcon from '../../Images/filterIcon.svg';
import Button from '@mui/material/Button';
import "@fontsource/open-sans";
import { useAppSelector } from '../../store';
import { useToken } from '../../useToken';
import saveAs from 'file-saver';
import { API_BASE_URL } from '../../urlConfig';
import downloadIcon from '../../Images/downloadPurpleIcon.svg';
import filterButtonIcon from '../../Images/filterButtonIcon.svg';
import styledE from "@emotion/styled";
import { FilterText } from '../Projects/ProjectsStyles';
import { MdPrint } from 'react-icons/md';
import { Item } from 'semantic-ui-react';
import JSZip, { folder } from 'jszip';
import printJS from 'print-js';
import FilterModal from './FilterModal';
import FoldersAcademicTab from './FoldersAcademicTab';
import FoldersISIRTab from './FoldersISIRTab';
/*import { cocatBlobs, postToServer } from 'ConcatenateBlobs';*/

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontSize: '16px',
    width: '1300px',
    display: 'flex', marginLeft: '24px'
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    //'&:nth-of-type(even)': {
    //    backgroundColor: 'rgba(131, 151, 248, 0.07)',
    //},
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));



const Folders = () => {
    const getToken = useToken();
    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;
    const selectedSchoolName = schoolState.name;

    const folderOptions = [
      /*  { value: 0, label: "FA" },*/
        { value: 0, label: "Academic" },
       /* { value: 2, label: "Tuition" },*/
        { value: 1, label: "ISIRS" }
    ]
    const [academicFolders, setAcademicFolders] = useState<StudentFolder[]>([]);
    const [isirFolders, setIsirFolders] = useState<StudentFolder[]>([]);
    const [filterTypes, setFilterTypes] = useState<string[]>([]);
    const [error, setError] = useState('');
    const [folderType, setFolderType] = useState<any>(folderOptions.find(x => x.value == 0));
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [showInactive, setShowInactive] = useState(false);
    const [showActive, setShowActive] = useState(true);
    const [allChecked, setAllChecked] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [seed, setSeed] = useState(1);



    const handleTabChange = (index: number) => {
        setFolderType(folderOptions.find(x => x.value == index));
    };
    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, studentId: number) => {
            const updatedFolders = [...academicFolders];
            updatedFolders.forEach((folder) => {
                if (folder.externalStudentId === studentId) {
                    folder.isChecked = event.target.checked;
                }
            });
            setAcademicFolders(updatedFolders);
            const isEveryBoxChecked = academicFolders.every((folder) => folder.isChecked);
            setAllChecked(isEveryBoxChecked);
      
    };
    const handleAllCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllChecked(event.target.checked);
        const allFolders = [...academicFolders];
        allFolders.forEach((folder) => {

            folder.isChecked = event.target.checked;

        });
        setAcademicFolders(allFolders);
   };

    const handleFilterModalClose = async() => {
        setOpenFilterModal(false);
        filterTypes.forEach((type) => {
            switch (type) {
                case type = "Alpha":
                    setAcademicFolders(academicFolders.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName)));
                    setSeed(Math.random());
                    break;
                case type = "StudentId":
                    setAcademicFolders(academicFolders.sort((a, b) => a.externalStudentId - b.externalStudentId));
                    setSeed(Math.random());
                    break;
                case type = "AllStudents":
                    setShowActive(true);
                    setShowInactive(true);
                    setSeed(Math.random());
                    break;
                case type = "ActiveStudents":
                    setShowInactive(false);
                    setShowActive(true);
                    setSeed(Math.random());
                    break;
                case type = "InactiveStudents":
                    setShowInactive(true);
                    setShowActive(false);
                    setSeed(Math.random());
                    break;
                case type = "Reset":
                    setAcademicFolders(academicFolders.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName)));
                    setShowInactive(false);
                    setShowActive(true);
                    setSeed(Math.random());
                    break;
            }
        });
        setLoading(false);
    };

    useEffect( () => {
        //if (academicFolders.length == 0) {
            loadData("Academic").then((data) =>
             setAcademicFolders(data));
       // }

       // if (isirFolders.length == 0) {
            loadData("ISIRS").then((data) =>
                setIsirFolders(data));
      //  }

        }, [selectedSchoolId]);

    const loadData = async (folderCategory: string) => {
        let data: StudentFolder[] = [];
        setLoading(true);
         try {
            const localToken = await getToken();
            await axios.get<StudentFolder[]>
                (`${API_BASE_URL}/api/Folders/getAllFolders/${selectedSchoolId}/${folderCategory}`,
                    {
                        headers: { Authorization: `Bearer ${localToken?.accessToken}` }
                    }).then((res) => {
                        data = res.data;
                        return res.data;

                })
                .catch(error => {
                    console.log(error?.response?.data);

                });
            
             setLoading(false);

        } catch ({ message }) {
             setError('An unknown error occurred' || message);
             setLoading(false);
        }
        return data;
    };

    //const handleSelectedFoldersDownload = async () => {
    //    const unresolvedPromises = academicFolders.map(async (folder) => {
    //        if (folder.isChecked) {
    //            downloadStudentFolder(folder.studentFiles, folder.firstName + "_" + folder.lastName + "_" + folder.externalStudentId, folder.externalStudentId, folder.schoolId, folder.folderType);
    //            }
    //     });
    //    const results = await Promise.all(unresolvedPromises);
    //}

    //const downloadStudentFolder = async (studentFiles: StudentFile[], studentName: string, studentId: number, schoolId: number, folderCategory: string) => {


    //    const zip = new JSZip();
    //    for (let i = 0; i < studentFiles.length; i++) {
    //        try {
    //            const localToken = await getToken();
    //            const filePath = studentFiles[i].filePath;
    //            const fileName = studentFiles[i].fileName;
    //            const newFilePath = filePath.replaceAll('/', '*');
    //            logDownloadStudentFolder(fileName, studentId, schoolId, folderCategory);
    //            const response = await axios.get(`${API_BASE_URL}/api/Folders/downloadFile/${fileName}/${newFilePath}`

    //                , {
    //                    responseType: 'arraybuffer',
    //                    headers: {
    //                        'Authorization': `Bearer ${localToken?.accessToken}`
    //                    }
    //                });
    //            const blob = new Blob([response.data], { type: 'application/octet-stream' });
    //            zip.file(studentFiles[i].fileName, blob);

    //        } catch (e) {
    //            console.error('Download failed', e);
    //                 }
    //    }
    //   zip.generateAsync({ type: "blob" })
    //        .then(function (content) {
    //            saveAs(content, studentName+ ".zip");
    //        });        
    //};

    //const logDownloadStudentFolder = async (fileName: string, studentId: number, schoolId: number, folderCategory: string) => {
    //    const localToken = await getToken();
    //    axios.post(`${API_BASE_URL}/api/Logger/addDownloadStudentFileLog/${fileName}/${schoolId}/${studentId}/${folderCategory}`, {}
    //        , {
    //            headers:
    //            {
    //                'Authorization': `Bearer ${localToken?.accessToken}`
    //            }
    //        })
    //        //.then(() => {
    //        //    console.log( "hit here");
    //        //  //  setResponse("Successfully updated user schools.");

    //        //})
    //        .catch(error => {
    //            console.log(error?.response?.data);

    //        })
    //};

    //const handleFilesPrint = async (studentFiles: StudentFile[]) => {
    //    const blobs : Blob[]=[];
    //    for (let i = 0; i < studentFiles.length; i++) {
    //        try {
    //            const localToken = await getToken();
    //            const filePath = studentFiles[i].filePath;
    //            const fileName = studentFiles[i].fileName;
    //            const newFilePath = filePath.replaceAll('/', '*');

    //            const response = await axios.get(`${API_BASE_URL}/api/Folders/downloadFile/${fileName}/${newFilePath}`

    //                , {
    //                    responseType: 'arraybuffer',
    //                    headers: {
    //                        'Authorization': `Bearer ${localToken?.accessToken}`
    //                    }
    //                });
    //            const arr1 = new Uint8Array(response.data);
    //            const buf1 = arr1.buffer;
    //            const blob = new Blob([response.data], { type: 'application/octet-stream' });
    //            blobs.push (blob);
    //            //const uri = URL.createObjectURL(blob);(studentFiles[i].fileName,
    //            //printJS({ printable: uri });

    //        } catch (e) {
    //            console.error('Download failed', e);
    //        }   }
    //        //zip.generateAsync({ type: "blob" })
    //        //    .then(function (content) {
    //    // saveAs(content, studentName + ".zip");
    //   // let uri = '';

    //   // uri = URL.createObjectURL(blobs);
    //    const combineBlob = new Blob(blobs, { type: 'application/octet-stream' });
    //    const uri2  = window.URL.createObjectURL(combineBlob);
    //                printJS({ printable: uri2 });
    //                //const uri = URL.createObjectURL(blobs);
    //                //const iframe = document.createElement('iframe'); //load content in an iframe to print later
    //                //document.body.appendChild(iframe);

    //                //iframe.style.display = 'none';
    //                //iframe.src = uri;
    //                //iframe.onload = function () {
    //                //    setTimeout(function () {
    //                //        iframe.focus();
    //                //        iframe?.contentWindow?.print();
    //                //    }, 1);
    //                //};

    //           // });        
     





    //    //try {
    //    //    if (blob != null) {
    //    //        const uri = URL.createObjectURL(blob);
    //    //        printJS({ printable: uri });
    //    //    }

    //    //} catch (e) {
    //    //    console.error('Download failed', e);
    //    //    alert('Download failed   ' + e.message);
    //    //}

    //};

    const tabStyle = {
        padding: '10px 20px',
        cursor: 'pointer',
        listStyleType: 'none',
        border:' 0px solid #fff'
    };

    const activeTabStyle = {
        ...tabStyle,
        borderBottom: '3px solid',
        borderRadius: '2px',


    };
    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
       
            {selectedSchoolName && (
                <>
                    <div className="pageHeader">Student Files</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}></div>
                    <TableContainer sx={{ marginTop: '30px', width: '90%', maxWidth: '1400px', minHeight: '300px', overflowX: 'auto', backgroundColor: 'white', borderRadius: '20px', boxShadow: '0px 0px 20px rgba(131, 151, 248, 0.13)' }}>

                        <Box>
                                <Tabs onSelect={handleTabChange}>
                                    <TabList style={{ display: 'flex' }} >
                                      {/*  <Tab className='faTabColor' style={folderType?.value === 0 ? activeTabStyle : tabStyle}>Financial Aid</Tab>*/}
                                        <Tab className='academicTabColor' style={folderType?.value === 0 ? activeTabStyle : tabStyle}>Academic</Tab>
                                  {/*      <Tab className='tuitionTabColor' style={folderType?.value === 2 ? activeTabStyle : tabStyle}>Tuition</Tab>*/}
                                        <Tab className='viewAllTabColor' style={folderType?.value === 1 ? activeTabStyle : tabStyle}>ISIRS</Tab>
                                </TabList>

                                <TabPanel>
                                    <FoldersAcademicTab studentFolders={academicFolders }  />
                                 
                                </TabPanel>
                             
                                <TabPanel>
                                    <FoldersISIRTab studentFolders={isirFolders} />
                             </TabPanel>
                                </Tabs>
                            </Box>
                   
                      
                        
                    </TableContainer>
                </>
            )}
        </>
    );
};

export default Folders;


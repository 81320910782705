import React, { useState } from 'react';
import { CompleteProjectBodyCell, IncompleteProjectBodyCell, OverdueProjectBodyCell, ToDoProjectBodyCell, UploadedProjectBodyCell } from './ProjectsStyles';



type Props = {
    projectDescription: string;
    status: string;

}

const ProjectCell = ({ projectDescription, status }: Props) => {
    if (status == 'Complete') {


        return (
            <CompleteProjectBodyCell>
                <div style={{ maxHeight: '50px', overflowY: 'auto' }}>    {projectDescription}</div>
            </CompleteProjectBodyCell>
        );
    }
    else if (status == 'Incomplete') {


        return (
            <IncompleteProjectBodyCell>
                <div style={{ maxHeight: '50px', overflowY: 'auto' }}>     {projectDescription}</div>
            </IncompleteProjectBodyCell>
        );
    }
    else if (status == 'Overdue') {


        return (
            <OverdueProjectBodyCell>
                <div style={{ maxHeight: '50px', overflowY: 'auto' }}>  {projectDescription}</div>
            </OverdueProjectBodyCell>
        );
    }
    else if (status == 'Uploaded') {


        return (
            <UploadedProjectBodyCell>
                <div style={{ maxHeight: '50px', overflowX: 'auto' }}> {projectDescription}</div> 
            </UploadedProjectBodyCell>
        );
    }
    else {//ToDo need type
        return (
            <ToDoProjectBodyCell>
                <div style={{ maxHeight: '50px', overflowY: 'auto' }}> {projectDescription}</div>
            </ToDoProjectBodyCell>
        );
    }
};
export default ProjectCell;

import React, { useState } from 'react';
import SchoolSelector from '../SchoolSelector';
import Button from '@mui/material/Button';
import { useAuth0, User } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import { SendChangePasswordEmail } from './AdminStyles';
import { useToken } from '../../useToken';
import axios from 'axios';
import { UserModel } from '../../types';
import { Box, FormControlLabel, IconButton, Radio, RadioGroup, Tooltip } from '@mui/material';
import personIcon from '../../Images/personIcon.svg';
import UploadProfileImageModal from './UploadProfileImageModal';
import { API_BASE_URL } from '../../urlConfig';
import { useAppSelector } from '../../store';

const ClearYearlyOverview = () => {
    const schoolState = useAppSelector((state) => state.school.school);
    const getToken = useToken();
    const [successClearedYearlyOverview, setSuccessClearedYearlyOverview] = useState('');
    const [error, setError] = useState('');

    const clearYearlyOverview = async () => {
             

        if (confirm("Are you sure you want to clear the current Yearly Overview for " + schoolState.name + "?")) {
            const localToken = await getToken();
            await axios.post(`${API_BASE_URL}/api/Admin/ClearYearlyOverview/${schoolState.id}`, {},
                {
                    headers:
                    {
                        'Authorization': `Bearer ${localToken?.accessToken}`
                    }
                }).then(() => {
                    setSuccessClearedYearlyOverview('Successfully cleared Yearly Overview.');
                })
                .catch(error => {
                    setError(error.message || 'An unknown error occurred while clearing Yearly Overview.');
                });
        }
    };

    
    return (
        <div>
       

            <Button style={{ marginTop: '55px', margin: '15px', marginLeft:'0px', backgroundColor: '#7F96FF' }} variant='contained' onClick={() => { clearYearlyOverview() }}>Clear Yearly Overview</Button> 
           {successClearedYearlyOverview &&
                <div style={{ color: '#72E889', margin: '8px' }}>
                    {successClearedYearlyOverview}
                </div>
            }
            {error &&
                <div style={{ color: '#FB4A4A', margin: '8px' }}>
                    {error}
                </div>
            }
        </div>
    );
};

export default ClearYearlyOverview;

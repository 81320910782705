import React from 'react';
import {  ModalBody, ModalContent, ModalHeader, ModalOverlay } from '../Processing/ProcessingStyles';
import { CloseButton } from '../CloseButton';
import NeedResponse from './NeedResponse';

type Props = {
    needId: number;
    onClose: () => void;
}

const DownloadDocumentsModal = ({ onClose, needId}: Props) => {
    return (
        <ModalOverlay onClick={onClose}>
          
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>Submitted Responses</ModalHeader>

                <CloseButton onClick={onClose} />
                <ModalBody> <NeedResponse needId={needId} /></ModalBody>
               
            </ModalContent>
        </ModalOverlay>
    );
};

export default DownloadDocumentsModal;

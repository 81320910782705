import { Avatar, Box, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import React, { useEffect, useState } from 'react';
import envolopeIcon from '../Images/envolopeBrownIcon.svg';
import phoneIcon from '../Images/phoneBottomIcon.svg';
import { useToken } from '../useToken';
import axios from 'axios';
import { API_BASE_URL } from '../urlConfig';
import { UserModel } from '../types';
type Props = {
    //  title: string;
    //  lastName: string;
    // firstName: string;
    userEmail: string;
    hover: boolean;
}
// Define a list of custom colors
const colorPalette = [
    '#253872', // light navy
    //  '#d7c5bb', // light brown
    //  '#bbc5f6', // light blue
    ////  '#fdebeb', // light red
    ////  '#fff5e1', // light Yellow
    // // '#ebfbee', // light green
    //  '#d3d3d3',//light grey

];
const UserAvatar = ({ userEmail, hover }: Props) => {
    const getToken = useToken();
    const [user, setUser] = useState<UserModel>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const getUser = async () => {
        setLoading(true);
        const localToken = await getToken();
        if (userEmail) {
            try {
                const { data } = await axios.get(`${API_BASE_URL}/api/Department/GetContactInfoByEmail/${userEmail}`,
                    {
                        headers:
                        {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localToken?.accessToken}`
                        }
                    });
                setUser(data);
            }
            catch (ex) {
                setError("Error when getting user.");
            }
        }
    }
    function stringToColor(string: string, palette: string[]) {
        let hash = 0;
        let i;
        /* eslint-disable no-bitwise */
        // Generate a hash from the string
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        /* eslint-enable no-bitwise */
        // Map the hash to an index in the palette array
        const index = Math.abs(hash) % palette.length;
        // Return the color from the custom palette
        return palette[index];
    }
    function stringAvatar(name: string) {
        return {
            sx: {//'rgba(187, 150, 131, 0.4)',//
                //  bgcolor: stringToColor(name, colorPalette),
                bgcolor: '#fff',
                color: '#253872',
                fontFamily: 'Open Sans',
                fontSize: '14px',
                fontWeight: '600',
                border: '1px #808aac solid',
                boxShadow: '0px 0px 4px 0px #253872 '


            },
            children: `${name.split('-')[0][0]}${name.split('-')[1][0]}`,
        };
    }
    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#FFF',
            color: '#253872',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            // border: '1px solid #dadde9',
            boxShadow: '4px 4px 20px 0px rgba(37, 56, 114, 0.10)'
        },
    }));
    useEffect(() => {
        getUser();
    }, []);
    return (
        <>
            {hover &&
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Box sx={{ paddingLeft: '2px' }}>  <h3>{user?.title} {user?.firstName?.substring(0, 1)} {user?.lastName}</h3></Box>
                            <Box sx={{ paddingBottom: '4px', paddingRight: '4px', paddingLeft: '4px' }}>   <a href={'mailto:' + userEmail}><img width={'20px'} height={'20px'} src={envolopeIcon} style={{ paddingRight: '8px' }}></img></a>
                                <span  > {userEmail} </span></Box>
                            <Box sx={{ paddingBottom: '4px', paddingRight: '4px', paddingLeft: '4px' }}>
                                <a href={'tel:732.660.9090,' + 123}  ><img width={'23px'} height={'23px'} style={{ paddingRight: '8px' }} src={phoneIcon}></img></a>
                                <span> 732-660-9090 Ext:{user?.phoneExtension}   </span></Box>
                        </React.Fragment>
                    }
                ><Avatar {...stringAvatar(user?.firstName + '-' + user?.lastName)} />
                </HtmlTooltip>}
            {!hover && <Avatar {...stringAvatar(user?.firstName + '-' + user?.lastName)} />
            }
        </>
    );
};
export default UserAvatar;
import React, { useCallback, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import axios from 'axios';
import SubmitButton from './SubmitButton';
import { useToken } from "../useToken";
/*import { Button } from './Button';*/
import Button from '@mui/material/Button';
import { API_BASE_URL } from '../urlConfig';
import { ConfirmText } from './ConfirmText';

interface Props {
    uploadPath: string;
    onSuccess: () => void;
    acceptedFileTypes: Accept;
}


const UploadFile = ({ uploadPath, onSuccess, acceptedFileTypes  }: Props) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState('');

    const dropzoneStyles: React.CSSProperties = {
        border: '2px dashed #cccccc',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
    };

  //  const baseUrl = process.env.REACT_APP_API_BASE_URL;

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setResponse('');
        setSelectedFile(acceptedFiles[0]);
    }, []);


    const getToken = useToken();
    const handleUpload = async () => {
        const localToken = await getToken();
        if (selectedFile) {
            setLoading(true);

            const formData = new FormData();
            formData.append('file', selectedFile, selectedFile.name);

            axios.post(`${API_BASE_URL}/${uploadPath}`, formData, {
                headers:
                {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localToken?.accessToken}`
                },
            })
                .then(() => {
                    onSuccess();
                    setLoading(false);
                })
                .catch(error => {
                    setResponse(error.response.data);
                    setLoading(false);
                })
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop, accept: acceptedFileTypes
    });

    return (

        <div>

            <div>
                <div {...getRootProps()}
                    style={dropzoneStyles}>

                    <input {...getInputProps()} />

                    <p>
                        Drag and drop the files here or click to select files

                        {selectedFile && (
                            <span>
                                <p>Selected File: {selectedFile.name}</p>
                            </span>
                        )}

                        {response && (
                            <span>
                                <p style={response == "Success" ? { color: 'green' } : { color: 'red' }}>Response: {response}</p>
                            </span>
                        )}
                    </p>
                </div>
            </div>
            {selectedFile && (
                <div style={{ paddingTop: '10px' }}>
                    <ConfirmText />
                    <Button onClick={handleUpload} variant="contained" disabled={loading} style={{ backgroundColor: '#253872' }}>Submit
                    </Button>
                </div>
            )}
        </div>

    );
};

export default UploadFile;

import React, { useState } from 'react';
import { ModalBody, ModalContent, ModalHeader, ModalOverlay } from '../Processing/ProcessingStyles';
import SchoolData from '../Processing/SchoolData';
import { CloseButton } from '../CloseButton';
import UploadMultipleFiles from '../UploadMultipleFiles';
import Loader from '../Loader';
import DownloadDocuments from './DownloadDocuments';

type Props = {
    projectDescription: string;
    projectId: number;
    schoolId: number | null;
    onClose: () => void;
}


const SchoolUploadModal = ({ onClose, projectDescription, projectId, schoolId }: Props) => {
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [componentKey, setComponentKey] = useState(0);

    const timerRef = React.useRef<ReturnType<typeof setTimeout> | undefined>(
        undefined,
    );
    const handleReset = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            setSuccess(false);
            setComponentKey(prevKey => prevKey + 1);
        }, 4000);
    };

    return (
        <ModalOverlay onClick={onClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>Projects and Reports Upload</ModalHeader>
                <CloseButton onClick={onClose} />
                {loading && <Loader />}
                <ModalBody>

                    <div>
                        <SchoolData projectDescription={projectDescription} projectId={projectId} />
                        {success ?
                            (<div style={{ color: '#5EB234' }}>File(s) Successfully Uploaded!</div>)
                            : <UploadMultipleFiles
                                uploadPath={`api/document/UploadSchoolProjectDocument/${schoolId}/${projectId}`}
                                // uploadPath={`api/document/uploadSchoolNeedDocument/${requirementId}`}
                                loading={loading}
                                setLoading={setLoading}
                                onSuccess={() => { setSuccess(true), handleReset() }}
                                acceptedFileTypes={{
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                                    'application/vnd.ms-excel': ['.xls'],
                                    'application/pdf': ['.pdf'],
                                    'text/plain': ['.txt'],
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                                }} />

                        } </div>
                    <DownloadDocuments key={componentKey} projectId={projectId} />

                </ModalBody>
            </ModalContent>
        </ModalOverlay>
    );
};

export default SchoolUploadModal;

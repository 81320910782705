import React from 'react';
import { StudentRequirement, SchoolRequirement } from '../../globalTypes/objects';

type Props = {
    /* requirement: SchoolRequirement;*/
    projectDescription: string;
    projectId: number;
}

const SchoolData = ({ projectDescription, projectId }: Props) => {
    return (
        <div style={{ paddingLeft: 12, paddingRight: 12, paddingBottom: 12 }}>
            <div>
                <h3 style={{ color: '#253872' }}>{projectDescription}</h3>
            </div>

            {/*<div>*/}
            {/*    <label style={{ fontWeight: 'bold' }}>Requirement: </label>*/}
            {/*    <span>{requirement.need}</span>*/}
            {/*</div>*/}
        </div>
    );
};

export default SchoolData;

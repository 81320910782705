import React, { useEffect, useState } from 'react';
import { Paper, TableContainer, Table, CircularProgress, TableBody, styled, TableRow, TableCell } from '@mui/material';
import { SearchBar } from '../SearchBar';
import { useToken } from '../../useToken';
import axios from 'axios';
import { API_BASE_URL } from '../../urlConfig';
import moment from 'moment';
import { FeedbackItem } from '../../globalTypes/objects';
import { FeedbackDate, FilterButton } from './FeedbackStyles';
import greyCheckIcon from '../../Images/greyCheckIcon.svg';
import greenCheckIcon from '../../Images/greenCheckIcon.svg';
import garbageIcon from '../../Images/blackGarbageIcon.svg';
import FilterModal from './FilterModal';
import filterButtonIcon from '../../Images/filterButtonIcon.svg';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontSize: '16px',
    //  border: 0
    color: 'black',
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({

    '&:nth-of-type(even)': {
        backgroundColor: 'rgba(131, 151, 248, 0.07)',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const formatDate = (dateString: string) => {
    const date = moment(dateString);
    const formatedDate = date.format("MMMM DD");
    return formatedDate;

};

const Feedback = () => {
    const getToken = useToken();
    const [searchValue, setSearchValue] = useState<string>("");
    const [feedbackList, setFeedbackList] = useState<FeedbackItem[]>([]);
    const [filteredList, setFilteredList] = useState<FeedbackItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [filterTypes, setFilterTypes] = useState<string[]>([]);
    const [openFilterModal, setOpenFilterModal] = useState(false);


    moment.locale('en-us');
    const getFeedback = async () => {
        try {
            const localToken = await getToken();
            const { data } = await axios.get(`${API_BASE_URL}/api/Feedback/getFeedback`, {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setFeedbackList(data);
            setFilteredList(data);
            setLoading(false);
            setError('');
        } catch (error: any) {
            //todo
            setError(error.message);
            console.log(error.message);
            setLoading(false);
        }
    };


    const resolveFeedback = async (resolved: boolean, feedbackId: number) => {
        const localToken = await getToken();
        setLoading(true);

        axios.post(`${API_BASE_URL}/api/feedback/ResolveFeedback`, { resolved: resolved, feedbackId: feedbackId }, {
            headers:
            {
                'Authorization': `Bearer ${localToken?.accessToken}`
            }
        })
            .then(() => {
                //  console.log(response);
                //  setSuccess(true);
                getFeedback();
                setLoading(false);

            })
            .catch(error => {
                // setResponse(error?.response?.data);
                setError(error?.response);
                setLoading(false);
            })
    };


    const deleteFeedback = async (feedbackId: number) => {
        const localToken = await getToken();
        setLoading(true);

        axios.post(`${API_BASE_URL}/api/feedback/DeleteFeedback`, { feedbackId: feedbackId }, {
            headers:
            {
                'Authorization': `Bearer ${localToken?.accessToken}`
            }
        })
            .then(() => {
                //  console.log(response);
                //  setSuccess(true);
                getFeedback();
                setLoading(false);

            })
            .catch(error => {
                // setResponse(error?.response?.data);
                setError(error?.response);
                setLoading(false);
            })
    };
    const handleFilterModalClose = async () => {
        setOpenFilterModal(false);
        filterTypes.forEach((type) => {
            switch (type) {
                case type = "UserAsc":
                    setFilteredList(filteredList.sort((a, b) => a.createdBy.localeCompare(b.createdBy)));
                    
                    break;
                case type = "UserDesc":
                    setFilteredList(filteredList.sort((a, b) => b.createdBy.localeCompare(a.createdBy) ));
                    break;
                case type = "Resolved":
                    setFilteredList(feedbackList.filter((a) => a.resolved));
                    break;
                case type = "Unresolved":
                    setFilteredList(feedbackList.filter((a) => !a.resolved));
                    break;
                case type = "DateAsc":
                    setFilteredList(filteredList.sort((a, b) => a.dateCreated.localeCompare(b.dateCreated)));
                    break;
                case type = "DateDesc":
                    setFilteredList(filteredList.sort((a, b) => b.dateCreated.localeCompare(a.dateCreated)));
                    break;
                case type = "All":
                    setFilteredList(feedbackList);
                    break;
                case type = "Clear":
                    setFilteredList(feedbackList);
                    break;
            }
        });
        setLoading(false);
    };

    useEffect(() => {
        if (feedbackList.length<1) {
        getFeedback().catch(error => {
            console.error('Error getting Feedback:', error);
        });
        }
       
    }, [loading, filterTypes]);


    return (

        <div>
            {openFilterModal && <FilterModal  onClose={() => { setLoading(true); handleFilterModalClose() }} filterTypes={filterTypes} setFilterFunction={setFilterTypes} />}
            <div className="pageHeader">Feedback</div>
            {/*  <div>   <SearchBar placeholder={'Search announcements'} searchValue={searchValue}></SearchBar></div>*/}
            {/*{searchValue}*/}
            {loading && <CircularProgress sx={{ color: '#B1B1B1', marginLeft: '45%' }} />}

            <FilterButton><img onClick={() => { setOpenFilterModal(true) }} src={filterButtonIcon} alt="Filter Icon" /></FilterButton>
            <TableContainer sx={{
                width: '85%', maxWidth: '1400px', minHeight: '100px', overflow: 'auto', padding: '0px', marginTop: '8px', borderRadius: '20px', background: 'white', boxShadow: '0px 0px 20px rgba(131, 151, 248, 0.13)'
            }} component={Paper}>
                <Table>
                 

                    <>        <TableBody sx={{ padding: 0 }}>
                        {filteredList &&
                            filteredList.map(feedback => (
                                    <StyledTableRow >
                                        <StyledTableCell sx={{ color: '#7F96FF ', width: '200px' }}> {feedback.createdBy}</StyledTableCell>
                                        <StyledTableCell>
                                            {feedback.feedbackText}
                                        </StyledTableCell>

                                        <StyledTableCell >
                                            <div style={{ display: 'flex', height: '100%' }}>
                                                <img style={{ cursor: 'pointer' }} title="Resolve Feedback" onClick={() => {
                                                   resolveFeedback(!feedback.resolved, feedback.feedbackId)
                                                }} height='15px' width='15px' src={feedback.resolved ? greenCheckIcon : greyCheckIcon} />
                                                <img style={{ cursor: 'pointer', paddingLeft: '8px' }} title="Delete Feedback" onClick={() => { deleteFeedback(feedback.feedbackId) }} height='15px' width='15px' src={garbageIcon} />
                                            </div></StyledTableCell>
                                        <StyledTableCell sx={{ width: '100px' }}><FeedbackDate> {formatDate(feedback.dateCreated)}</FeedbackDate></StyledTableCell>
                                    </StyledTableRow>
                                ))}
                        </TableBody>
                    </>
                </Table>
            </TableContainer>
         </div>

    );
};

export default Feedback;


import React, { useState } from 'react';
import SchoolSelector from '../SchoolSelector';
import Button from '@mui/material/Button';
import { useAuth0, User } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import { SendChangePasswordEmail } from './AdminStyles';
import { useToken } from '../../useToken';
import axios from 'axios';
import { UserModel } from '../../types';
import { Box, FormControlLabel, IconButton, Radio, RadioGroup, Tooltip } from '@mui/material';
import personIcon from '../../Images/personIcon.svg';
import UploadProfileImageModal from './UploadProfileImageModal';
import { API_BASE_URL } from '../../urlConfig';
import { useAppSelector } from '../../store';

const ClearNeeds = () => {
    const schoolState = useAppSelector((state) => state.school.school);
    const getToken = useToken();
    const [successClearNeeds, setSuccessClearNeeds] = useState('');
    const [error, setError] = useState('');
    const [needType, setNeedType] = useState('');

    const clearNeeds = async (needTypeId: number, needType: string) => {
             

        if (confirm("Are you sure you want to clear ALL the current " + needType + " needs for " + schoolState.name + "?")) {
            const localToken = await getToken();
            await axios.post(`${API_BASE_URL}/api/Admin/ClearAllNeeds/${schoolState.id}/${needTypeId}`, {},
                {
                    headers:
                    {
                        'Authorization': `Bearer ${localToken?.accessToken}`
                    }
                }).then(() => {
                    setSuccessClearNeeds('Successfully cleared all ' + needType + ' needs.');
                })
                .catch(error => {
                    setError(error.message || 'An unknown error occurred while clearing needs.');
                });
        }
    };

    
    return (
        <div>
       

            <Button style={{ marginTop: '55px', margin: '15px', marginLeft:'0px', backgroundColor: '#7F96FF' }} variant='contained' onClick={() => { clearNeeds(1, "Financial Aid") }}>Clear Financial Aid Needs</Button> 
            <Button style={{
                marginTop: '55px',
                margin: '15px', backgroundColor: '#BA9976' }} variant='contained' onClick={() => { clearNeeds(2, "Academic") }}>Clear Academic Needs</Button>
            <Button style={{ marginTop: '55px', margin: '15px', backgroundColor: '#253872' }} variant='contained' onClick={() => { clearNeeds(3,"Tuition") }}>Clear Tuition Needs</Button>
            {successClearNeeds &&
                <div style={{ color: '#72E889', margin: '8px' }}>
                    {successClearNeeds}
                </div>
            }
            {error &&
                <div style={{ color: '#FB4A4A', margin: '8px' }}>
                    {error}
                </div>
            }
        </div>
    );
};

export default ClearNeeds;

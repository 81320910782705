import React from 'react';
import {  ModalBody, ModalContent, ModalHeader, ModalOverlay } from '../Processing/ProcessingStyles';


import DownloadDocuments from './DownloadDocuments';
import { CloseButton } from '../CloseButton';

type Props = {
    projectId: number;
    onClose: () => void;
}

const DownloadDocumentsModal = ({ onClose, projectId}: Props) => {
    return (
        <ModalOverlay onClick={onClose}>
          
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>Submitted Documents</ModalHeader>

                <CloseButton onClick={onClose} />
                <ModalBody> <DownloadDocuments projectId={projectId } /></ModalBody>
               
            </ModalContent>
        </ModalOverlay>
    );
};

export default DownloadDocumentsModal;

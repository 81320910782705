import React, { useState } from 'react';
import SchoolSelector from '../SchoolSelector';
import Button from '@mui/material/Button';
import { Box, LinearProgress } from '@mui/material';


type Props = {
    onClose: () => void;
}



const SwitchSchool = ({ onClose }: Props) => {
    const [schoolUpdated, setSchoolUpdated] = useState<boolean>(false);
    const handleSchoolUpdated = () => {
        setSchoolUpdated(true);
    };
    return (
        <div>  <div className="pageHeader">Switch School</div>
            <br />
            <br />
            <div>Please Select School:</div>
            <br />
          
            <SchoolSelector onClose={() => { handleSchoolUpdated }} />
          
            <br />
            {schoolUpdated && <div style={{ color: '#5EB234' }}>Successfully updated school.</div>}
        </div>
            );
};

            export default SwitchSchool;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {  ModalBody, ModalContent, ModalHeader, ModalOverlay } from '../Processing/ProcessingStyles';
import { useToken } from "../../useToken";
import { CloseButton } from '../CloseButton';
import Button from '@mui/material/Button';
import { CircularProgress, TextField } from '@mui/material';
import { CommentDate, CommentHeader, CommentInfo, GarbageImg, IncompleteComment, } from './ProjectsStyles';
import { Note } from '../../globalTypes/objects';
import  garbageIcon  from '../../Images/garbageIcon.svg';
import moment from 'moment';
import Loader from '../Loader';
import { API_BASE_URL } from '../../urlConfig';


type Props = {
    projectDescription: string;
    notes: Note[];
    onClose: () => void;
}
const deletedNoteIds = [0];

const IncompleteCommentModal = ({ projectDescription,notes, onClose }: Props) => {
    moment.locale('en-us');
    const formatDate = (dateString: string) => {
        const date = moment.utc(dateString);//.subtract(4, 'hours');
        const estDate = date.tz('America/New_York'); 
        const formatedDate = estDate.format("MMM DD h:mm a");
        return formatedDate;

    };
    const [response, setResponse] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [seed, setSeed] = useState(1);

    const getToken = useToken();
   // const baseUrl = process.env.REACT_APP_API_BASE_URL;

    const handleDeleteComment = async (noteId: number) => {
        setLoading(true);
        const localToken = await getToken();

        axios.post(`${API_BASE_URL}/api/project/deleteProjectNote/${noteId}`, {  }
            , {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            })
            .then(() => {
                deletedNoteIds.push(noteId);
                console.log(response);
                setSuccess(true);
                setLoading(false);
                setSeed(Math.random());
               
            })
            .catch(error => {
                console.log(error?.response?.data);
                setLoading(false);
            })
    };

    //useEffect(() => {

    //}, [deletedNoteIds]);
    return (
        <ModalOverlay  onClick={onClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>Comment</ModalHeader>
                <CloseButton onClick={onClose} />
                <ModalBody key={seed}>
                    <div>
                        <div style={{ width: '100%' }}>

                            <div className="modal">
                                <CommentHeader>
                                    {projectDescription}
                                </CommentHeader>
                                <div className="modal-content" >
                                    <br />
                                    {notes.map((note) => (
                                       <>                                
                                            <div style={{ display: 'flex' }}>
                                                <CommentInfo> {note.createdBy} {/*? note.createdBy.indexOf('hecmanage') > -1 || note.createdBy.indexOf('academiasps') > -1 ? "HigherEd Staff" : "" : ""}*/}
                                            </CommentInfo>
                                            
                                            <CommentDate>{note.dateCreated ? formatDate(note.dateCreated) : ''}</CommentDate>
                                            <GarbageImg src={garbageIcon} onClick={() => (handleDeleteComment(note.noteId))}></GarbageImg></div>
                                            <IncompleteComment>
                                                {(note.deleted || deletedNoteIds.indexOf(note.noteId) > -1) && <i style={{ color: '#B1B1B1' }}>Deleted</i>}
                                                {!note.deleted && deletedNoteIds.indexOf(note.noteId) == -1  && <div>{note.note}</div>}
                                            </IncompleteComment>
                                            
                                     </>
                                
                                    ))}
                                    {loading && <CircularProgress sx={{ color: '#B1B1B1' }} />}
                                    </div>                         
                        </div>
                    </div>
                    </div>
                </ModalBody>
            </ModalContent>
        </ModalOverlay>
    );
};

export default IncompleteCommentModal;
import React, { useCallback, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import axios from 'axios';
import SubmitButton from '../SubmitButton';
import { useToken } from "../../useToken";
/*import { Button } from './Button';*/
import Button from '@mui/material/Button';
import { API_BASE_URL } from '../../urlConfig';
import { file } from 'jszip';

interface Props {
    uploadPath: string;
    //uploadedFile: File;
    onSuccess: () => void;
    setUploadFunction: any;
}


const UploadProjectFile = ({ uploadPath, onSuccess,  setUploadFunction }: Props) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState('');

    const projectImportAccept: Accept = {
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        'application/vnd.ms-excel': ['.xls'],
        'application/pdf': ['.pdf'],
        'text/plain': ['.txt'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
    };

    const dropzoneStyles: React.CSSProperties = {
        border: '2px dashed #cccccc',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
    };

  //  const baseUrl = process.env.REACT_APP_API_BASE_URL;

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setResponse('');
        setSelectedFile(acceptedFiles[0]);
        setUploadFunction(acceptedFiles);
        setSelectedFiles(acceptedFiles);
     //   uploadedFile = acceptedFiles[0];
    }, []);


   

    const { getRootProps, getInputProps } = useDropzone({
        onDrop, accept: projectImportAccept
    });

    return (

        <div>

            <div>
                <div {...getRootProps()}
                    style={dropzoneStyles}>

                    <input {...getInputProps()} />

                    <p>
                        Drag and drop the files here or click to select files

                        {/*{selectedFile && (*/}
                        {/*    <span>*/}
                        {/*        <p>Selected File: {selectedFile.name}</p>*/}
                        {/*    </span>*/}
                        {/*)}*/}
                        {selectedFiles && (
                            <span>
                                <div>Selected File(s):{selectedFiles.map(file => (
                                    <div>  {file.name}</div>
                                )) } </div>
                            </span>
                        )}
                        {response && (
                            <span>
                                <p style={response == "Success" ? { color: 'green' } : { color: 'red' }}>Response: {response}</p>
                            </span>
                        )}
                    </p>
                </div>
            </div>
            {/*{selectedFile && (*/}
            {/*    <div style={{ paddingTop: '10px' }}>*/}
            {/*        <Button onClick={handleUpload} variant="contained" disabled={loading} style={{ backgroundColor: '#253872' }}>Submit*/}
            {/*        </Button>*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>

    );
};

export default UploadProjectFile;

import React, { useContext, useEffect, useState, ChangeEvent } from 'react';
/*import SelectedSchoolContext from './SelectedSchoolContext';*/
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useToken } from '../../useToken';
import Select, {  MultiValue } from 'react-select';
import makeAnimated from 'react-select/animated';
import { School } from '../../types';
import { API_BASE_URL } from '../../urlConfig';

type Props = {
    selectedSchools: School[];
}




const customStyles = {
    container: (provided: any) => ({
        ...provided,
        marginTop: '24px',
        textAlign:'left',
        backgroundColor: 'white'
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        boxShadow: state.isFocused && '1px solid #a1a8ca',
        border: state.isFocused ? '1px solid #a1a8ca' : '1px solid #e5e5ea',
   
        '&:hover': {
            border: state.isFocused && '1px solid #a1a8ca',
        },
    }),
};
const MultipleSchoolSelector = ({selectedSchools }: Props) => {
 
    const [schools, setSchools] = useState<{ id: number, name: string }[]>([]);
    const additionalSelectedSchools: (string | MultiValue<{ label: string; value: number; }>)[] =[];
    const getToken = useToken();

    const getSchools = async () => {
        try {
            const localToken = await getToken();

            const { data } = await axios.get(`${API_BASE_URL}/api/School/getAll`, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setSchools(data);
        } catch (error: any) {
            console.log(error.message || 'An unknown error occurred');
        }
    };

    // Fetch the schools from your API
    useEffect(() => {
        getSchools();
        console.log('init' +selectedSchools);
    }, [selectedSchools]);

    const handleSchoolChange = (event: MultiValue<{ label: string, value: number }>) => {
       event.map((x) => {
            if (selectedSchools.filter(n => n.id == x.value).length < 1) {
                selectedSchools.push({ id: x.value, name: x.label });
            }
           
        });

        selectedSchools.map((x,index) => {
            const selecterIndex = event.filter(n => n.value == x.id).length;
            if (selecterIndex < 1 ) {
                selectedSchools.splice(index, 1);
            }
          
        })
          
       additionalSelectedSchools.push(event);
        
    };
   
    const animatedComponents = makeAnimated();
    console.log(selectedSchools);
    return (
        <>   {schools && <Select
            options={schools?.map(school => ({
                label: school.name,
                value: school.id,

            }))}
            components={animatedComponents}
            onChange={(event) => handleSchoolChange(event)}
            styles={customStyles}
            isMulti={true}
            placeholder="Select School" defaultValue={selectedSchools.map(school => ({
                label: school.name ?? "",
                value: school.id,

            }))}
        ></Select>
            

        }</>

    );
}

export default MultipleSchoolSelector;
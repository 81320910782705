import React, { useState } from 'react';
import styled from '@emotion/styled';
import downloadIcon from '../../Images/downloadPurpleIcon.svg';
import { IconButton } from '@mui/material';



const DownloadIcon = () => {

    return (
        <IconButton sx={{ width: '30px', height: '30px', marginLeft: '10px' }}>

            <img width={'30px'} height={'30px'} src={downloadIcon} alt="Download Icon" />
      

        </IconButton>
    );
};
export default DownloadIcon;

import React, { useEffect, useState } from 'react';
import { FilterOption, ModalBody, ModalContent, ModalHeader, ModalOverlay } from './ProcessingStyles';
import { CloseButton } from '../CloseButton';
import { Button, FormControl, MenuItem, Select } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { StudentRequirement } from '../../globalTypes/objects';
//import Select, { SingleValue } from 'react-select';
type Props = {
    onClose: () => void;
    filterTypes: string[];
    dateFilterType: string;
    setFilterFunction: any;
    setDateFilterFunction: any;
    requirements: StudentRequirement[];

}
interface DateRangeType {
    startDate: Date | null;
    endDate: Date | null;
}
const customStyles = {
    container: (provided: any) => ({
        ...provided,
        width: 255, backgroundColor: 'white'

    }),
    control: (provided: any, state: any) => ({
        ...provided,

        boxShadow: state.isFocused && '1px solid #a1a8ca',
        border: state.isFocused ? '1px solid #a1a8ca' : '1px solid #e5e5ea',
        height: 48,
        '&:hover': {
            border: state.isFocused && '1px solid #a1a8ca',
        },
    }),
};
const needDateOptions = [
    "One Day",
    "Three Days",
    "One Week",
    "Two Weeks",
    "One Month",
    "Two Months",
    "Six Months",
    "One Year"
];
const FilterModal = ({ onClose, filterTypes, setFilterFunction, dateFilterType, setDateFilterFunction, requirements }: Props) => {
    const [selectedFilterTypes, setSelectedFilterTypes] = useState<string[]>(filterTypes);
    /* const [needDateOptions, setSchools] = useState<{ id: number, name: string }[]>([]);*/
    const [dateFilterSelected, setDateFilterSelected] = useState<string>(dateFilterType);
    const [dates, setDates] = useState<DateRangeType | null>({
        startDate: new Date(),
        endDate: new Date(),
    });
    // setSelectedFilterTypes(filterTypes);
    const specificNeedDateOptions: string[] = [];
    (requirements.map((req) => {
        req.needs.map(need => {
            const dateExists = specificNeedDateOptions.findIndex(x => x == need.needDate);
            if (dateExists < 0) specificNeedDateOptions.push(need.needDate)
        });

    }));
    specificNeedDateOptions.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA.getTime() - dateB.getTime(); // Compare timestamps
    });
    const specificResponseDateOptions: string[] = [];
    (requirements.map((req) => {
        req.needs.map(need => {
            const dateExists = specificResponseDateOptions.findIndex(x => x == need.latestNeedResponseDate);
            if (dateExists < 0 && need.latestNeedResponseDate) specificResponseDateOptions.push((need.latestNeedResponseDate))
        });
    }));
    specificResponseDateOptions.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA.getTime() - dateB.getTime(); // Compare timestamps
    });
    // const [specificDateOptions, setSpecificDateOptions] = useState<string[]>([]);
    //const handleDateChange = (newValue: Moment | null) => {
    //    if (newValue) {
    //        // Check if the date is already in the selected dates array
    //        const isDateSelected = selectedDates.some(date => date.isSame(newValue, 'day'));
    //        if (isDateSelected) {
    //            // Remove the date if it's already selected
    //            setSelectedDates(selectedDates.filter(date => !date.isSame(newValue, 'day')));
    //        } else {
    //            // Add the date to the selectedDates array if not selected
    //            setSelectedDates([...selectedDates, newValue]);
    //        }
    //    }
    //};
    const handleDateChange = (dateOption: string) => {

        setDateFilterSelected(dateOption);
        // onClose();
    };
    const handleFilterChangeOld = (filterType: string) => {

        if (selectedFilterTypes.includes(filterType)) {
            setSelectedFilterTypes(selectedFilterTypes.filter((type) => type !== filterType));
        }
        else {
            setSelectedFilterTypes([...selectedFilterTypes, filterType]);
        }
        if (filterType == "ResponseDate") {
            setSelectedFilterTypes(selectedFilterTypes.filter((type) => type !== 'NeedDate'));
        }
        if (filterType == "Clear") {
            setSelectedFilterTypes(["Clear"]);
        }

    };
    const handleFilterChange = (filterType: string) => {
        setSelectedFilterTypes((prevFilterTypes) => {
            let updatedFilterTypes: string[];
            // Toggle the filterType in the list
            if (prevFilterTypes.includes(filterType)) {
                updatedFilterTypes = prevFilterTypes.filter((type) => type !== filterType);
            } else {
                updatedFilterTypes = [...prevFilterTypes, filterType];
            }
            // If "ResponseDate" is selected, remove "NeedDate" if it exists
            if (filterType === "ResponseDate") {
                updatedFilterTypes = updatedFilterTypes.filter((type) => type !== 'NeedDate');
                //  setSpecificDateOptions(specificResponseDateOptions);
            }
            if (filterType === "NeedDate") {
                updatedFilterTypes = updatedFilterTypes.filter((type) => type !== 'ResponseDate');
                // setSpecificDateOptions(specificNeedDateOptions);
            }
            if (filterType === "Resolved") {
                updatedFilterTypes = updatedFilterTypes.filter((type) => type !== 'Unresolved');
            }
            if (filterType === "Unresolved") {
                updatedFilterTypes = updatedFilterTypes.filter((type) => type !== 'Resolved');
            }
            if (filterType === "StudentName") {
                updatedFilterTypes = updatedFilterTypes.filter((type) => type !== 'StudentId');
            }
            if (filterType === "StudentId") {
                updatedFilterTypes = updatedFilterTypes.filter((type) => type !== 'StudentName');
            }
            // If "Clear" is selected, reset to only "Clear"
            if (filterType === "Clear") {
                updatedFilterTypes = ["Clear"];
            }
            return updatedFilterTypes; // Return the updated filter types list
        });
    };
    const formatDate = (dateString: string) => {
        const date = moment(dateString);
        const formatedDate = date.format("MMM DD YYYY");
        return formatedDate;
    };
    const applyFilters = () => {
        setSelectedFilterTypes(selectedFilterTypes);
    };
    const handleSelect = (ranges: any) => {
        setDates({
            startDate: ranges.range1.startDate,
            endDate: ranges.range1.endDate,
        });
    };
    useEffect(() => {
        setFilterFunction(selectedFilterTypes);

    }, [selectedFilterTypes]);
    useEffect(() => {
        setDateFilterFunction(dateFilterSelected);
    }, [dateFilterSelected]);
    return (
        <ModalOverlay onClick={onClose}>

            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>Filter</ModalHeader>
                <CloseButton onClick={onClose} />
                <ModalBody>
                    <div style={{ marginLeft: '10px', marginTop: '24px' }}>
                        {/*                    <FilterOption style={selectedFilterTypes.includes("Alpha") ? { border: '2px #253872 solid', boxShadow:'1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('Alpha') }}>Alphabetical Order</FilterOption>*/}
                        <FilterOption style={selectedFilterTypes.includes("NeedDate") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('NeedDate') }}>Date</FilterOption>
                        <FilterOption style={selectedFilterTypes.includes("ResponseDate") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('ResponseDate') }}>Response Date</FilterOption>
                        {<FilterOption style={selectedFilterTypes.includes("Resolved") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('Resolved') }}>Resolved</FilterOption>}
                        {<FilterOption style={selectedFilterTypes.includes("Unresolved") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('Unresolved') }}>Unresolved</FilterOption>}
                        {/*                    {<FilterOption style={selectedFilterTypes.includes("Deleted") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('Deleted') }}>Deleted</FilterOption>}*/}
                        {<FilterOption style={selectedFilterTypes.includes("StudentName") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('StudentName') }}>Student Name</FilterOption>}
                        {<FilterOption style={selectedFilterTypes.includes("StudentId") ? { border: '2px #253872 solid', boxShadow: '1px solid #a1a8ca' } : { border: '0px' }} onClick={() => { handleFilterChange('StudentId') }}>Student ID</FilterOption>}
                    </div>
                    {(selectedFilterTypes.includes("NeedDate")) &&
                        <div style={{ marginLeft: '30px', marginTop: '24px' }}>
                            <div style={{ display: "flex" }}>
                                <h4 style={{ width: '100px' }}>Date within:</h4>
                                {needDateOptions &&
                                    <FormControl variant="standard"> <Select
                                        value={dateFilterSelected}

                                        displayEmpty
                                        onChange={(e) => setDateFilterSelected((e.target.value))}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200, // Set the maximum height of the dropdown menu
                                                    overflowY: 'auto', // Enable vertical scrolling
                                                },
                                            },
                                        }}
                                        style={{ marginLeft: "12px", marginTop: '10px', textAlign: "left", width: '180px', height: '45px', border: '#253872' }}
                                    >
                                        {needDateOptions &&
                                            needDateOptions.map(option => (
                                                <MenuItem value={option}>{option}</MenuItem>))}
                                    </Select></FormControl>}
                            </div>

                            <div style={{ display: "flex" }}>
                                <h4 style={{ width: '100px' }}>Specific Date:</h4>
                                {specificNeedDateOptions &&
                                    <FormControl variant="standard">
                                        <Select
                                            value={dateFilterSelected}
                                            displayEmpty
                                            onChange={(e) => setDateFilterSelected((e.target.value))}
                                            style={{ marginLeft: "12px", marginTop: '10px', textAlign: "left", width: '180px', height: '45px', border: '#253872' }}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 200, // Set the maximum height of the dropdown menu
                                                        overflowY: 'auto', // Enable vertical scrolling
                                                    },
                                                },
                                            }}
                                        >
                                            {specificNeedDateOptions &&
                                                specificNeedDateOptions.map(option => (
                                                    <MenuItem value={option}>{formatDate(option)}</MenuItem>))}
                                        </Select>
                                    </FormControl>}
                            </div>
                        </div>
                    }
                    {selectedFilterTypes.includes("ResponseDate") &&
                        <div style={{ marginLeft: '30px', marginTop: '24px' }}>
                            <div style={{ display: "flex" }}>
                                <h4 style={{ width: '100px' }}>Date within:</h4>
                                {needDateOptions &&
                                    <FormControl variant="standard"> <Select
                                        value={dateFilterSelected}
                                        displayEmpty
                                        onChange={(e) => setDateFilterSelected((e.target.value))}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200, // Set the maximum height of the dropdown menu
                                                    overflowY: 'auto', // Enable vertical scrolling
                                                },
                                            },
                                        }}
                                        style={{ marginLeft: "12px", marginTop: '10px', textAlign: "left", width: '180px', height: '45px', border: '#253872' }}
                                    >
                                        {needDateOptions &&
                                            needDateOptions.map(option => (
                                                <MenuItem value={option}>{option}</MenuItem>))}
                                    </Select></FormControl>}
                            </div>
                            <div style={{ display: "flex" }}>
                                <h4 style={{ width: '100px' }}>Specific Date:</h4>
                                {specificResponseDateOptions &&
                                    <FormControl variant="standard">
                                        <Select
                                            value={dateFilterSelected}
                                            displayEmpty
                                            onChange={(e) => setDateFilterSelected((e.target.value))}
                                            style={{ marginLeft: "12px", marginTop: '10px', textAlign: "left", width: '180px', height: '45px', border: '#253872' }}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 200, // Set the maximum height of the dropdown menu
                                                        overflowY: 'auto', // Enable vertical scrolling
                                                    },
                                                },
                                            }}
                                        >
                                            {specificResponseDateOptions &&
                                                specificResponseDateOptions.map(option => (
                                                    <MenuItem value={option}>{formatDate(option)}</MenuItem>))}
                                        </Select>
                                    </FormControl>}
                            </div>
                        </div>
                    }
                    <div>
                        <Button onClick={async () => {
                            applyFilters();
                            onClose()
                        }}
                            variant="contained" style={{ backgroundColor: '#253872', marginTop: '45px' }}>Apply
                        </Button>
                        <Button onClick={() => {
                            handleFilterChange('Clear');
                            // applyFilters();
                            // onClose();
                        }}
                            variant="outlined" style={{ color: '#253872', borderColor: '#253872', marginLeft: '5px', marginTop: '45px' }}>Clear
                        </Button>
                    </div>
                </ModalBody>

            </ModalContent>
        </ModalOverlay>
    );
};
export default FilterModal;
import React, { useContext, useEffect, useState, ChangeEvent } from 'react';
import SelectedSchoolContext from './SelectedSchoolContext';
import axios from "axios";
import {useAuth0} from "@auth0/auth0-react";
import { useToken } from '../useToken';
import Select, { SingleValue } from 'react-select';
import { setSchoolState } from '../store/schoolSlice';
import { useAppSelector, useAppDispatch } from "../store/index";
import { API_BASE_URL } from '../urlConfig';


type Props = {
    onClose: () => void;
}

const customStyles = {
    container: (provided: any) => ({
        ...provided,
        width: 355, backgroundColor: 'white'
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        boxShadow: state.isFocused && '1px solid #a1a8ca',
        border: state.isFocused ? '1px solid #a1a8ca' : '1px solid #e5e5ea',
        height: 48,
        '&:hover': {
            border: state.isFocused && '1px solid #a1a8ca',
        },
    }),
};
const SchoolSelector = ({ onClose }: Props) => {
    //const contextValue = useContext(SelectedSchoolContext);
    //if (!contextValue) {
    //    throw new Error("SelectedSchoolContext is not provided");
    //}
    //const { isLoadingSchools, setIsLoadingSchools,  setSelectedSchoolId,  setSelectedSchoolName } = contextValue;

    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;
    const selectedSchoolName = schoolState.name;
    const [schools, setSchools] = useState<{ id: number, name: string }[]>([]);
    const [isLoadingSchools, setIsLoadingSchools] = useState<boolean>(false);
    const {getAccessTokenSilently} = useAuth0();
    const getToken = useToken();
  //  const baseUrl = process.env.REACT_APP_API_BASE_URL;
    
    const [token,setToken]= useState('');
    const dispatch = useAppDispatch();
    const getSchoolsForUser = async()=>{
        const localToken = await getToken();
       setIsLoadingSchools(true);
       try {
           fetch(`${API_BASE_URL}/api/School/getSchoolsForUser`, {
               headers:
                   {
                       'Authorization': `Bearer ${localToken?.accessToken}`
                   }
           })
               .then(response => response.json())
               .then(data => {
                   setSchools(data);
                   setIsLoadingSchools(false);
                   
                   //if(selectedSchoolId == null|| selectedSchoolId == 0 && data.length > 0){
                   //    setSelectedSchoolId(data[0].id);
                   //    setSelectedSchoolName(data[0].name);
                   //}// Set the first school as the default
               });
       }
       catch ({message}) {
            console.log('An unknown error occurred' || message);
        } finally {
            setIsLoadingSchools(false);
        }
    }
    // Fetch the schools from your API
    useEffect(() => {
    getSchoolsForUser();
    }, []);

    const handleSchoolChange = (event: SingleValue<{ label: string, value: number }>) => {
     //   const selectedOption = event.target.options[event.target.selectedIndex];
        // setSelectedSchoolId(Number(event.target.value));
       // setSelectedSchoolName(selectedOption.getAttribute('data-name') || '');
        dispatch(setSchoolState({ name: event?.label || '', id: Number(event?.value) }));
        onClose();
    };

    return (
        <>   {schools && <Select
            options={schools?.map(school => ({
                label: school.name,
                value: school.id,

            }))}
            onChange={(event) => handleSchoolChange(event)}
            styles={customStyles}
            placeholder="Select School" defaultValue={{ label: selectedSchoolName||'', value:selectedSchoolId ||0 }}></Select>
            
         
          }</>
 
    );
}

export default SchoolSelector;